<section *ngIf="isData">
    <div class="card">
        <div class="card-header">

            <div class="float-left">
                <a [routerLink]="['/autoeval']" [queryParams]="{ folder: myFormInstrument.FormFolderID }" class="btn btn-sm btn-outline-primary ladda-button">Volver</a>
            </div>

            <span class="cat__core__title text-center d-block">
                <strong>
                    Resultados de Instrumento: {{myFormInstrument.NameForm}}
                </strong>
                <div *ngFor="let indexForm of myFormInstrument.IndexForms">
                    {{indexForm.NameIndexForm}}, {{indexForm.IndexFormUsers.length}} Usuarios con rol {{indexForm.TypeRole}} encuestados
                </div>
            </span>
        </div>
    </div>

    <div *ngFor="let module of myFormInstrument.FormModules; let i = index" class="card">
        <div class="card-header hd-blue">
            <h5 class="text-black float-left uppercase">
                <strong>{{module.NameModule}}</strong>
            </h5>
        </div>
        <div *ngFor="let field of module.FormFields" class="card-block">
            <h5 class="text-black uppercase">
               {{field.NameField}}
            </h5>
            <div class="row">

                <div class="col-3 graph">
                    <ngx-charts-pie-chart
                        [scheme]="colorScheme"
                        [results]="field.graph[0].series"
                        [explodeSlices]=true
                        >
                    </ngx-charts-pie-chart>
                </div>

                <div class="col-9 graph">
                    <ngx-charts-bar-vertical-stacked
                    [scheme]="colorScheme"
                    [results]="field.graph"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [yAxisLabel]="yAxisLabel">
                </ngx-charts-bar-vertical-stacked>
                </div>

                


            </div>

            
        </div>


       
        <div class="graph">
            <ngx-charts-advanced-pie-chart 
                    [results]="module.graph"
                    [gradient]="false" >
            </ngx-charts-advanced-pie-chart>
        </div>
        <button class="btn btn-sm btn-primary" (click)="exportData()" >Exportar datos</button>
    </div>

</section>

