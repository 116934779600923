<section>

    <autoeval-users-filter *ngIf="isData" [schoolID]="newIndexForm.SchoolID" [isRolFilter]="true" [back]="'/autoeval'"
        [title]="'ENVIAR INSTRUMENTO: ' + myFormInstrument.NameForm" (userSchoolChoice)="setSchool($event)"
        (userRoleChoice)="setRole($event)" (userList)="setUsers($event)" (CourseList)="setCourses($event)">
    </autoeval-users-filter>

    <div class="card">
        <div class="card-block">
            <h5 class="text-black">
                <strong>Selecciona las opciones para indexar encuesta</strong>
            </h5>

            <div class="row">

                <div class="col-lg-4">
                    <div class="form-group">
                        <label class="">Elija tipo de oferta</label>
                        <select class="form-control" (change)="onTypeOferChange()"
                            [(ngModel)]="isActiveOfer">
                            <option [ngValue]="true">Avtivas</option>
                            <option [ngValue]="false">Inactivas</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label class="">Oferta</label>
                        <select  class="form-control" [(ngModel)]="courseOferSelected"(change)="onCourseOferChange()">
                            <option *ngFor="let ofer of coursesOfers" [ngValue]="ofer">{{ofer.NameCourseOfer}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group ">
                        <label class="">Materia</label>
                        <select #Subject class="form-control" [(ngModel)]="subjectSelected" (change)="onSubjectChange()">
                            <option *ngFor="let subject of subjects" [ngValue]="subject">{{subject.NameSubject}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group ">

                        <label class="">Profesor</label>
                        <select class="form-control" (change)="onSubjectGrouphange()"
                            [(ngModel)]="newIndexForm.UserProfesorID">
                            <option [ngValue]="eTypeRole.Individual">Individual</option>
                            <option [ngValue]="eTypeRole.Estudiante">Estudiante</option>
                            <option [ngValue]="eTypeRole.Egresado">Egresado</option>
                            <option [ngValue]="eTypeRole.Docente">Docente</option>
                            <option [ngValue]="eTypeRole.Administrativos">Administrativos</option>
                        </select>

                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="exampleInputPassword1">Desde</label>
                        <input class="form-control" type="date" [(ngModel)]="newIndexForm.Since">
                      </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="exampleInputPassword1">Hasta</label>
                        <input class="form-control" type="date" [(ngModel)]="newIndexForm.Until">
                      </div>
                </div>
            </div>


            <h5 class="text-black">
                <strong>Título Encuesta</strong>
            </h5>

            <div class="form-group row">
                <div class="col-sm-10">
              <input type="text" class="form-control w-100" [(ngModel)]="newIndexForm.NameIndexForm"
                    placeholder="Nombre de la encuesta">
                </div>
                <button (click)="saveIndexForm()" type="submit" class="btn btn-primary col-sm-2 col-form-label">Enviar</button>

                
            </div>

        </div>
    </div>

    <div *ngFor="let module of myFormInstrument.FormModules; let i = index" class="card">
        <div class="card-header hd-blue">
            <h5 class="text-black float-left uppercase">
                <strong>{{module.NameModule}}</strong>
            </h5>
        </div>
        <div *ngFor="let field of module.FormFields" class="card-block">
            <autoeval-survey [role]="newIndexForm.TypeRole" [field]=field></autoeval-survey>
        </div>
    </div>

</section>