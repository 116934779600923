export class SubjectGroup {
    public id:number;
    public NameSubjectGroup:string;
    public CourseGroupID:number;
    public SubjectID: number;
    public MidTerms: number;
    public DateStart:Date;
    public DateEnd:Date;
    public UserID: number;
    public CodeClassRoom: string;
    public CodeBlackBoard: string;
    public IsDateChanged:boolean;
    public IsPhysicalTest:boolean;
    public IsActive: boolean;
    public TotalPeople:number;
    public VirtualLink: string;

    public userapp: {CedocEmail:string}
    
}
