

<section class="row">
	<div class="col-md-12">
		<div class="float-left">
			<button  (click)="navigate()" class="btn btn-sm btn-outline-primary ladda-button">Crear Noticia</button>
		</div>
	</div>
</section>
<br>
<section class="row">
	<div class="col-md-3">
		<div class="card">
			
			<div class="card-header">
				<h5 class="mb-0 text-black ">
					<strong *ngIf="principalAPPNAME == 'cedoc'">Ver por Escuela</strong>
					<strong *ngIf="principalAPPNAME != 'cedoc'">Ver por Liceo</strong>
				</h5>
			</div>
			<ul class="list-group list-selectable list-group-flush">
				<li (click)="onSchoolChange(schools)"
				[class.active]="schoolSelected.length>1" class="list-group-item">
					<a class="" *ngIf="principalAPPNAME == 'cedoc'">Todos las escuelas</a>
					<a class="" *ngIf="principalAPPNAME != 'cedoc'">Todos los Liceos</a>
				</li>
				<li *ngFor="let schl of schools"   (click)="onSchoolChange([schl])"
					[class.active]="schoolSelected.length==1 &&schoolSelected[0].id==schl.id" class="list-group-item">
					<a>{{schl.NameTSchool}}</a>
				</li>
			</ul>
		</div>
	</div>
    

    <div class="col-md-9">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header hd-blue">
                        <h5 *ngIf="schoolSelected.length < 5" class="mb-0 text-black">
                            <strong>Mostrando por: </strong>
                            <span *ngFor="let catSel of schoolSelected"> {{catSel.NameTSchool}} </span>
                        </h5>

                        <h5 *ngIf="schoolSelected.length > 5" class="mb-0 text-black">
                          <strong *ngIf="principalAPPNAME == 'cedoc'">Mostrando Todas las Escuelas </strong>
													<strong *ngIf="principalAPPNAME != 'cedoc'">Mostrando Todos los Liceos </strong>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let article of myArticles; index as i" class="col-md-6">
                <div class="card">
                    <div class="card-header py-0">
										<div class="row">
											<div class="col-2">
												<h4 class=" my-0 divPublico">
													<img [src]="SCHOOL_LOGO_DYNAMIC_SVG(article.SchoolID)" class="imgHeader rounded-logo">
												</h4>
											</div>
											<div class="col-6">
												<h4 class="my-0">	
													<div>
														<h4 style="font-weight: bold; margin-top: 10px;">{{article.Title}}</h4>
													</div>
													<small style="font-weight: bold;" >{{article.IsPrivate? 'Privada': 'Pública' }}</small>
													<br>
													<small style="font-size: 12px;">
														{{convertToDateOnly(article.CreateAt)}}
													</small>
													<br>
													<small style="font-size: 12px;">{{article.isApproved? 'Aprobado': 'No aprobado' }}</small>
												</h4>
											</div>
											<div class="col-4" *ngIf="publicarArticleBtn">
												<div class="divPublico" >
													<div>
														<strong>Aprobado  </strong>
														<br>
														<input type="checkbox" (change)="PublicarArticle(article, i)" [checked]="article.isApproved" />
													</div>
													<br>
													<div>
														<strong>Privada  </strong>
														<br>
														<input type="checkbox" (change)="PrivateArticle(article, i)" [checked]="article.IsPrivate" /> 
													</div>
													<br>
												</div>
											</div>
										</div>
                    </div>

                    <div class="card-block">
											<div class="row">
												<div class="col-sm-6 col-xs-12">
													{{article.Summary}}
													<div [innerHTML]="setHtmlContent(article.Description)"></div>
													<small class="text-muted">
														Creado por: {{article?.careArea ? article?.careArea?.CareAreaName : "Bienestar"}}
													</small>
												</div>
												<div class="col-sm-6 col-xs-12">
													<img *ngIf="!article.Imagen" [src]="SCHOOL_PHOTO_DYNAMIC(myArticle.SchoolID)" class="img-fluid">
													<img *ngIf="article.Imagen" src="{{article.Imagen}}" class="img-fluid">
												</div>
											</div>
                    </div>

                    <div class="card-btns" >
											<button *ngIf="showDeleteBtn" class="btn  btn-primary" (click)="deleteArticle(article, i)">Eliminar</button>
											<button class="btn btview btn-primary" (click)="mostrarPublicacion(article, i)">Previsualizar</button>
											<button class="btn btn-primary" (click)="EditarPublicacion(article, i)">Editar</button>
                    </div>
                </div>
            </div>
        </div>
    <!-- modal de Visualización -->
    <div class="transfer-modal__container" [ngStyle]="{'visibility': showViewModal ? 'visible' : 'hidden'}" >
        <div class="transfer-modal">
            <div class="transfer-modal__header-container">
                <div class="transfer-modal__header">
                    <button (click)="viewModalContent()" class="button "><em class="icon-i-close close-icon"></em></button>
                </div>
            </div>
            <div>
                <div _ngcontent-avd-c10="" >
                    <div class="row">								
										<div _ngcontent-avd-c10="" class="col-5 contentInfo">
											<div>
												<span _ngcontent-avd-c10="" target="_blank" class="news-data__info-tittle" >{{myArticle.Title}} </span>
												<div _ngcontent-avd-c10="" class="news-data__info-by" *ngIf="categoriesSelect"  >Creado por: {{categoriesSelect}}</div>
												<a _ngcontent-avd-c10="" target="_blank" rel="noopener" href={{myArticle.linkArticle}}  *ngIf="myArticle.linkArticle">
													<div _ngcontent-avd-c10="" class="newsLink"><strong _ngcontent-avd-c10="">Ver enlace</strong>
													</div>
												</a>
												<div _ngcontent-vge-c10="" class="news-data__info-separator"></div>
												<div _ngcontent-avd-c10="" style="height: 240px;overflow-y: auto;" [innerHTML]="setHtmlContent(myArticle.Description)" >
												</div>
											</div>
										</div>
										<div _ngcontent-avd-c10="" class="col-7 divImageModal">
											<img *ngIf="!this.myArticle.Imagen" [src]="SCHOOL_PHOTO_DYNAMIC(this.myArticle.SchoolID)" class="img-fluid">
											<img _ngcontent-avd-c10="" alt="img Noticia" src={{this.myArticle.Imagen}} *ngIf="this.myArticle.Imagen" width="80%" >
										</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
		<!-- modal de Edición de publicacion -->
		<div class="transfer-modal__container" [ngStyle]="{'visibility': showViewModalEdit ? 'visible' : 'hidden'}" >
			<div class="transfer-modal">
					<div class="transfer-modal__header-container">
							<div class="transfer-modal__header">
									<button (click)="viewModalContentEdit()" class="button "><em class="icon-i-close close-icon"></em></button>
							</div>
					</div>
					<div>
							<div _ngcontent-avd-c10="" >
								<div class="row">								
									<div _ngcontent-avd-c10="" class="col-5 contentInfo">
										<div>
											<strong class="texto spacio">Escriba aquí el título</strong>
											<input _ngcontent-avd-c10="" target="_blank" class="form-control spacio" [(ngModel)]="myArticle.Title" />
											<strong class="texto spacio">Ingresar el enlace (URL) que desea relacionar </strong>
											<input class="form-control spacio " [(ngModel)]="myArticle.linkArticle"  />
											<strong class="texto spacio">Escriba aquí el contenido (Máx. 2000 caracteres)</strong>
											<angular-editor [config]="editorConfig"  class="textArea spacio" [(ngModel)]="myArticle.Description">{{myArticle.Description}}</angular-editor>											
										</div>
									</div>
									<div _ngcontent-avd-c10="" class="col-7 divImageModalEd">
										<!-- <img *ngIf="!this.myArticle.Imagen" [src]="SCHOOL_PHOTO_DYNAMIC(this.myArticle.SchoolID)" class="img-fluidEdi"> -->
										<label class="inputFile btnCargarImagen">
											<i class="fa fa-file-image-o"></i> Cargar imagen
											<input type="file" name="DocumentFile"  style="display: none;"  accept="image/*" (change)="uploadFileDocuments($event)">
											<br>
										</label>
										<br>
										<img _ngcontent-avd-c10="" alt="img Noticia" src={{this.myArticle.Imagen}} *ngIf="this.myArticle.Imagen" width="80%" >

										<div class="mt-3 textoInformativo">
											<div class="styleTexto">
												<span >
													El archivo que no cumpla con estas restricciones no podrá ser cargado. 
												</span>
												<span>
													* Por favor, asegurarse de que la imagen que desea cargar, cumple con las siguientes restricciones de tamaño y formato: <br>
													Tamaño máximo de archivo: 5 megabytes (5MB)
												</span>
												<span>
													Formatos de archivo permitidos: .jpg,.jpeg,.png,.gif,.ico.
												</span>
											</div>
										</div>
									</div>
									<div class="col-5"></div>
									<div class="card-btns col-2" >
										<button class="btn  btn-primary" (click)="GuardarCambios(this.myArticle)">Guardar Cambios</button>
									</div>
									<div class="col-5"></div>
								</div>
							</div>
					</div>
			</div>
		</div>
    </div>
</section>