import { FormInstrument } from './formInstrument';

export class FormFolder {
    public id:number;
    public SchoolID:number;
    public Name:string;
    public FolderID: number;

    public FormInstruments: FormInstrument[];
    public childresnFolders: FormFolder[];
    public parentFolder: FormFolder;
}
