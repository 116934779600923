import { IndexForm } from './indexForm';
import { UserAnswer } from './userAnswer';

export class IndexFormUser {
    public id:number;
    public UserID:number;
    public IndexFormID:number;
    public IsAnswer:boolean;
    public Percentage:number;

    public IndexForm:IndexForm;
    public UserAnswers:UserAnswer[];
}
