import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { School } from 'src/app/models/school';
import { SchoolService } from 'src/app/services/school.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'autoeval-curtain-menu',
  templateUrl: './curtain-menu.component.html',
  styleUrls: ['./curtain-menu.component.css']
})
export class CurtainMenuComponent implements OnInit {
  public principalAppName = environment.principalAppName.toLowerCase();
  @Input()
    schoolID:number
  @Output()
    userSchoolChoice = new EventEmitter<School>();
  mySchool: number;
  school:School = new School();
  public heightExp = "0%"
  constructor(private schoolService: SchoolService) { }

  ngOnInit(): void {
    this.mySchool = this.schoolID;
    this.getSchools(this.schoolID);
    this.schoolService.clickSchoolId = this.schoolID
  }

  openNav() {
    this.heightExp = "100%";
  }

  closeNav() {
    this.heightExp = "0%";
    this.userSchoolChoice.emit(this.school);
  }

  getSchools(schoolID){
    this.schoolService.clickSchoolId = schoolID
    let filter = this.mySchool == schoolID ?  "" : ',"parentSchool"'
    this.schoolService.getSchoolsAndChildren(schoolID,filter).subscribe(
      d => {this.school=d
        if(this.school.childrenSchools?.length<=2)
          this.closeNav()
      }
    );
  }
}
