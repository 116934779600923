import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { TemplateMainComponent } from './pages/template-main/template-main.component';
import { TemplateEditComponent } from './pages/template-edit/template-edit.component';
import { TemplateIndexComponent } from './pages/template-index/template-index.component';
import { TemplateBoardComponent } from './pages/template-board/template-board.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { NewsComponent } from './pages/news/news.component';
import { TemplateUserComponent } from './pages/template-user/template-user.component';




const routes: Routes = [

  { path: 'autoeval', component: TemplateMainComponent },
  { path: 'autoeval/edit/:id', component: TemplateEditComponent },
  { path: 'autoeval/index/:id', component: TemplateIndexComponent },
  { path: 'autoeval/dashboard', component: TemplateBoardComponent },
  { path: 'autoeval/user/:id', component: TemplateUserComponent },
  { path: 'wellness', component: BlogComponent },
  { path: 'wellness/article/:id', component: ArticleComponent },
  { path: 'wellness/email/news', component: NewsComponent },
  { path: 'wellness/noticia/new', component: NewsComponent },
  
  { path: '**', component: EmptyRouteComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class AppRoutingModule { }
