import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormField, TypeQuestion } from 'src/app/models/formField';
import { TypeRole } from 'src/app/models/indexForm';

@Component({
  selector: 'autoeval-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  @Input()
    field: FormField;

  @Input()
    role: TypeRole;

  @Output()
    value = new EventEmitter<string>();

  public isDelete = false;
  public eTypeQuestion = TypeQuestion;
  public isDisplay = true;
  
  public eTypeRole = TypeRole;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (changes.role.currentValue) {
      case TypeRole.Estudiante:
        if(this.field.PcStudents==0)
          this.isDisplay=false;
        else 
          this.isDisplay=true;
        break;

      case TypeRole.Egresado:
        if(this.field.PcAlumni==0)
          this.isDisplay=false;
        else 
          this.isDisplay=true;
        break;

      case TypeRole.Docente:
        if(this.field.PcProfesors==0)
          this.isDisplay=false;
        else 
          this.isDisplay=true;
        break;

      case TypeRole.Individual:
        if(this.field.PcIndividual==0)
          this.isDisplay=false;
        else 
          this.isDisplay=true;
        break;

      case TypeRole.Administrativos:
        if(this.field.PcAdmons==0)
          this.isDisplay=false;
        else 
          this.isDisplay=true;
        break;  
    }
  }

  onValueChange(value){
    console.log(value)
    this.value.emit(value);
  }
}
