<div class="card">
        
    <div class="card-block">
        <div class="float-left">
            <a [routerLink]="['/autoeval']" [queryParams]="{ folder: myFormInstrument.FormFolderID }" class="btn btn-sm btn-outline-primary ladda-button">Volver</a>
        </div>

        <span class="cat__core__title centertext d-block">
            <strong>
                EDITAR PLANTILLA DE INSTRUMENTO
            </strong>
        </span>

        <h6 class="text-black mt-3 uppercase">
            <strong>Nombre de Plantilla</strong>
        </h6>
        <input type="text" class="form-control" [(ngModel)]="myFormInstrument.NameForm" placeholder="Nombre de plantilla">

        <h6 class="text-black mt-3 uppercase">
            <strong>descripción</strong>
        </h6>
        <textarea class="form-control"   [(ngModel)]="myFormInstrument.Descript" rows="3"></textarea>

        <h6 class="text-black mt-3 uppercase">
            <strong>Porcentaje por Roles</strong>
        </h6>
        <div class=" role-percentage">
           
            <div class="">
                <input type="number" [(ngModel)]="myFormInstrument.PcIndividual" >
                <i class="fa fa-street-view"></i>
                <span> Individual</span>
            </div>
            <div class="">
                <input type="number" [(ngModel)]="myFormInstrument.PcAdmons">
                <i class="fa fa-laptop"></i>
                <span> Administrativos</span>
            </div>
            <div class="">
                <input type="number" [(ngModel)]="myFormInstrument.PcProfesors" >
                <i class="fa fa-book"></i>
                <span> Docentes</span>
            </div>
            <div class="">
                <input type="number" [(ngModel)]="myFormInstrument.PcStudents" >
                <i class="fa fa-user"></i>
                <span> Estudiantes</span>
            </div>
            <div class="">
                <input type="number" [(ngModel)]="myFormInstrument.PcAlumni" >
                <i class="fa fa-graduation-cap"></i>
                <span> Egresados</span>
            </div>
        </div>
        <div class="mt-4 text-center">
            <a [routerLink]="['/autoeval']" [queryParams]="{ folder: myFormInstrument.FormFolderID }" class="btn btn-sm mr-2 btn-outline-primary ">Salir</a>
            <button (click)="editTemplate(myFormInstrument)" class="btn btn-sm btn-primary ">Guardar</button>
        </div>
    </div>

</div>

<div  *ngFor="let module of myFormInstrument.FormModules; let i = index" class="card">
    
    <div *ngIf="!module.isEdit" class="card-header hd-blue">
        <h5 class="text-black float-left uppercase">
            <strong>{{module.NameModule}}</strong>
        </h5>
        <div class="float-right d-inline">
            {{module.Percentage}}% Condición
            <button (click)="module.isEdit=true" class="btn btn-sm btn-primary">Editar</button>
        </div>
    </div>
    <div *ngIf="module.isEdit" class="card-header hd-blue">
        <h5 class="text-black float-left uppercase">
            <input type="text" class="form-control"  [(ngModel)]="module.NameModule"  placeholder="Nombre de Condición">
        </h5>
        <div class="module-percentage">
            <input class="input" type="number"  [(ngModel)]="module.Percentage"  placeholder="Porcentaje Condición">
            % Porcentaje Condición
            <button (click)="editModule(module)" class="btn btn-sm btn-primary ">Guardar</button>
        </div>
    </div>

    <div *ngFor="let field of module.FormFields" class="card-block">
        <autoeval-question-elm [field]=field ></autoeval-question-elm>
    </div>

    <div  class="newField">
         <i (click)="newField(module.id, i)" class="fa fa-plus-circle"></i>
    </div>
</div>

<div (click)="newModule()" class="newModule">
    <i class="fa fa-plus"></i> NUEVO MÓDULO 
</div>