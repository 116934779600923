import { FormModule } from './formModule';
import { IndexForm } from './indexForm';


export class FormInstrument {
    public id:number;
    public SchoolID:number;
    public NameForm:string;
    public Descript:string;
    public FormFolderID:number;

    public PcAdmons:number;
    public PcProfesors:number;
    public PcStudents:number;
    public PcAlumni:number;
    public PcIndividual:number;
    
    public IndexForms:IndexForm[];
    public FormModules:FormModule[];

}
