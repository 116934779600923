import { FormInstrument } from './formInstrument';
import { IndexFormUser } from './indexFormUser';

export class IndexForm {
    public id:number;
    public FormID:number;
    public NameIndexForm:string;
    public Descript:string;
    public TypeRole:TypeRole;
    public Percentage:number;

    public SchoolID:number;
    public TypeCourseID:number;
    public CourseID:number;
    public SubjectID:number;
    public CourseOferID:number;
    public CourseGroupID:number;
    public SubjectGroupID:number;
    public UserProfesorID:number;

    public Since:Date;
    public Until:Date;
    public Population:number;

    public FormInstrument:FormInstrument;
    public IndexFormUsers:IndexFormUser[];
}

export enum TypeRole {
    Individual = 'Individual',
    Estudiante = 'Estudiante',
    Egresado = 'Egresado',
    Docente = 'Docente',
    Administrativos = 'Administrativos',
    Acudiente = 'Acudiente',
}
