<section>
    <div class="card">
        <div class="card-header blue-title">
            <h5 class="mb-0 uppercase">
                <strong>Bienvenido al blog de bienestar</strong>
            </h5>
        </div>
    </div>

</section>


<section class="row">

    <div class="col-md-3">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0 text-black uppercase">
                    <strong>Ver por Escuela</strong>
                </h5>
            </div>
            <ul class="list-group list-selectable list-group-flush">
                <li [class.active]="schoolSelected.length>1" class="list-group-item">
                    <a class="">Todos las escuelas</a>
                </li>
                <li *ngFor="let schl of schools"
                    [class.active]="schoolSelected.length==1 &&schoolSelected[0].id==schl.id" class="list-group-item">
                    <a>{{schl.NameTSchool}}</a>
                </li>
            </ul>
        </div>
    </div>
    
      <div class="col-md-9">
          
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-block">
                      <h2 class="card-title font-weight-bold">Card title</h2>
                      <h4 class="card-subtitle mb-2 text-muted">Card subtitle</h4>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                  </div>
            </div>
        </div>
      </div>
</section>