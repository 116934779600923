import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Article, TypeArticle } from 'src/app/models/article';
import { WellnessService } from 'src/app/services/wellness.service';
import { CareArea } from 'src/app/models/careArea';
import { environment } from 'src/environments/environment';
import { School } from 'src/app/models/school';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserApp } from 'src/app/models/userApp';
import * as ICAL from 'ical.js';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { forkJoin } from 'rxjs';
import { SchoolService } from 'src/app/services/school.service';
import { HttpService } from 'src/app/services/http.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'autoeval-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef;
  InputVariable:File= null
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '172px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo' ]
    ]
  };
  public charCount = 0
  public cantidad = 2000
  public typeNotificacion = ''
  public urlHttp: string = environment.baseUrl;
  public myArticle: Article = new Article();
  public fileToUpload: File;
  public icsFile: File;
  public errorMessage: string = 'Digite los datos';
  public submitbutonName: string = 'Publicar';
  public namePost: string = 'noticia'
  public enablesavefile = false;
  public btnclass = "btn-outline-primary"
  public categories: CareArea[] = [new CareArea()];
  public mySchool = 25;
  public eTypeArticle = TypeArticle;
  public stateType = this.eTypeArticle.article;
  public isRolefilter = false;
  public isAuth = false;
  public usersList: { UserID: number, CedocEmail: string }[] = [];
  public currentUser: UserApp;
  public notificationQueMessage = []
  public tiposDocumentos: string[] = ['image/jpg', 'image/jpeg', 'image/jpe', 'image/png', 'image/gif', 'image/x-icon']
  public tiposDocumentosPDF: string[] = ['image/jpg', 'image/jpeg', 'image/jpe', 'image/png', 'image/gif',  'image/x-icon', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  public showViewModal: boolean = false;
  public validaImage = false
  public fechaInicio = new Date()
  public fechaFinal  = new Date()
  public fileUp = false
  public url = ''
  public principalAppName = environment.principalAppName.toLowerCase();
  public schoolPrincipal = true
  tituloNoticia = ''
  htmlContent: SafeHtml;
  @Input()
    schoolID:number
    school:School = new School();
    currentRoute: string;
  f;
  constructor(
    private wellnessService: WellnessService,
    private router: Router,
    private userService: UserService,
    private _sweetalertService: SweetalertService, 
    private schoolService: SchoolService,
    private route: ActivatedRoute,
    private _httpService: HttpService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
  ) {
    this.f = this.formBuilder.group({})
  }

  ngOnInit(): void {  
    this.mySchool = this.schoolID;
    
    this.getRoles();
    this.myArticle.IsPrivate = true;
    this.myArticle.isApproved = this.principalAppName =='celic'?false:true
    this.route.url.subscribe(url => {
      this.currentRoute = url[1].path;
      if(this.currentRoute=='email'){
        this.setMail()
        this.typeNotificacion = 'bulk'
      }else{
        this.typeNotificacion = 'news'
      }
    });
    
  }

  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  getRoles() {
    this.userService.getMyRoles().subscribe(
      roles => {
        roles.forEach(role => {
          if (
            role.role.name == "Bienestar" || 
            role.role.name == "Extensión Egresado" || 
            role.role.name == "Egresados" || 
            role.role.name == "Extensión" || 
            role.role.name == "Biblioteca"
          ) {
            this.isAuth = true;
            this.myArticle.SchoolID = role.SchoolID;
            this.mySchool = role.SchoolID;
            this.currentUser = role.userapp
          }
        });

        if (!this.isAuth) this.router.navigate(['/']);

        this.getCategories()
      },
      error => {
        console.log(error);
        this.router.navigate(['/']);
      }
    )
  }

  getCategories() {
    if(this.myArticle.SchoolID){
      this.wellnessService.getCareAreaBySchool([25, this.myArticle.SchoolID]).subscribe(
        data => {
          this.categories = data;
        }
      );
    }

  }

  setArticle() {
    this.tituloNoticia =  this.myArticle.Title
    this.stateType = this.eTypeArticle.article;
    this.isRolefilter = false;
    this.namePost = "noticia";
    this.typeNotificacion = 'news'
    this.myArticle.IsPrivate = false;
    this.DOC_FILE_NAME = '';
    this.fileUp = false
    this.myArticle.Title = ''
    this.myArticle.Imagen = ''
    this.myArticle.linkArticle = ''
    this.myArticle.Description = ''
  }
  setEvent() {
    this.tituloNoticia =  this.myArticle.Title
    this.typeNotificacion = 'event'
    this.stateType = this.eTypeArticle.event;
    this.isRolefilter = true;
    this.namePost = "evento";
    this.ICS_FILE_URL = '';
    this.fileUp = false
    this.myArticle.Title = ''
    this.myArticle.Imagen = ''
    this.myArticle.linkArticle = ''
    this.myArticle.Description = ''
  }
  setNews() {
    this.tituloNoticia =  this.myArticle.Title
    this.stateType = this.eTypeArticle.news;
    this.isRolefilter = false;
    this.namePost = "noticia";
    this.fileUp = false
    this.myArticle.Title = ''
    this.myArticle.Imagen = ''
    this.myArticle.linkArticle = ''
    this.myArticle.Description = ''
  }
  setMail() {
    this.tituloNoticia =  this.myArticle.Title
    this.typeNotificacion = 'bulk'
    this.stateType = this.eTypeArticle.email;
    this.isRolefilter = true;
    this.namePost = "correo";
    this.fileUp = false
    this.myArticle.Title = ''
    this.myArticle.Imagen = ''
    this.myArticle.linkArticle = ''
    this.myArticle.Description = ''
    
  }
  setSchool(school: School) {
    this.myArticle.SchoolID = school.id;
    this.getCategories();
  }

  setUsers(users: { UserID: number, CedocEmail: string }[]) {
    this.usersList = users;
  }
  getCategoria(i){
    return this.categories?.filter(x=> x.id == i)[0]?.CareAreaName
  }
  validateForm(model: Article, isValid: boolean) {
    this.myArticle.SchoolID = this.schoolService.clickSchoolId;
    this.getCategories()
    this.errorMessage = "Corrija los datos erróneos";
    if(this.charCount>2 && this.charCount<=2000){
      if (!isValid || !model.Title ) {
        Swal.fire('Error', 'Formulario Invalido!', 'info');
        return;
      }
      if ((this.stateType != TypeArticle.article) && this.usersList && this.usersList.length == 0) {
        Swal.fire('Error', 'Se debe seleccionar al menos un usuario para enviar el correo', 'info');
        return;
      }
      this.errorMessage = "Validando ... ";
      this.submitbutonName = "Validando ... ";
      if (this.stateType == TypeArticle.email) this.sendMail();
      if (this.stateType == TypeArticle.article) this.sendNews();
      if (this.stateType == TypeArticle.event) this.createCalendarEvent(); 
      this.charCount = 0
    }else{
      Swal.fire('Error', 'Formulario Invalido!', 'info');
        return;
    }
    

  }

  sendMailOld() {
    this.wellnessService.newEmail(this.usersList.map(r => r.CedocEmail), this.myArticle).subscribe(
      p => {
        let text =  this.namePost=='noticia'? 'Noticia'+' enviada correctamente': this.namePost=='correo'?'Correo'+' enviado correctamente':'Evento'+' enviado correctamente'
        this.errorMessage = "Nuevo " +this.namePost+ " enviado";
        this.submitbutonName = "Publicar"
        this.myArticle = new Article();
        this.myArticle.SchoolID = this.mySchool;
        this.btnclass = "btn-outline-primary";
        Swal.fire(
          '',
          text,
          'success'
        )
      },
      e => {
        this.errorMessage = "No se pudo enviar";
        this.submitbutonName = "Volver a intentar"
        Swal.fire('No se pudo enviar', '', 'error')
      });
  }
  onContentChange(content: string): void {
    var regex = /(<([^>]+)>)/ig;
    const textContent = content.replace(regex, "");
    this.charCount = textContent.length;
  }

  sendMail() {
    if (this.usersList && this.usersList.length == 0) {
      Swal.fire('Error', 'Se debe seleccionar al menos un usuario para enviar el correo', 'info');
      return;
    }
    let newDate: Date = new Date();
    
    this.notificationQueMessage =[]

    this.usersList.forEach(element => {
      this.myArticle.Description =this.myArticle.Description.replace(/"/g, "'")
      let data = {
        emailsTo: `["${element.CedocEmail}"]`,
        namesTO: `["${this.myArticle.Title}"]`,
        msg: `[{"message":"${ this.myArticle.Description }"}]`,
        timeToSend: newDate.toISOString(),
        isSend: false,
        isSingleMessage: true,
        typeNotification: this.typeNotificacion,
        senderName: `${this.currentUser.LastName1} ${this.currentUser.Name1}`,
        senderEmail: `${this.currentUser.email}`,
        atachment: null,
        templateFile: 'template.html'
      }
      let datas = []
      // if (this.ICS_FILE_URL != '') data.atachment = `[{"name":"calendario.ics","url":"${this.ICS_FILE_URL}"}]`; 
      // if (this.DOC_FILE_NAME != '') data.atachment = `[{"name":"${this.DOC_FILE_NAME}","url":"${this.myArticle.Imagen}"}]`;
      if (this.ICS_FILE_URL != '') datas.push({"name":"calendario.ics","url":this.ICS_FILE_URL}); 
      if (this.DOC_FILE_NAME != '') datas.push({"name":this.DOC_FILE_NAME,"url":this.myArticle.Imagen});

      data.atachment = JSON.stringify(datas).replace(/\\/g, "")
      this.notificationQueMessage.push(data);
    });

    this.postNotificQueue()
    // this.cleanInput()
  }

  cleanInput(){
    this.tituloNoticia =  this.myArticle.Title
    this.ICS_FILE_URL = '';
    this.myArticle.Imagen = ''; 
    this.myArticle.Title=''
    this.myArticle.linkArticle=''
    this.myArticle.Description=''
    // this.myArticle.CareAreaID=undefined
    this.fechaInicio = new Date()
    this.fileUp = false
    this.DOC_FILE_NAME = '',
    this.eliminaFoto()

  }
  
  postNotificQueue() {
    let observableList:any = []
    this._sweetalertService.swalLoading('Cargando Datos', 'Esto puede tomar un tiempo dependiendo de su conexión a internet')
    this.notificationQueMessage.forEach(element => {
      observableList.push(this.wellnessService.postNotificationQueue( element ))
    });
    forkJoin(
      [...observableList]
    ).subscribe( {
      next: ( data ) => {
        let texto = this.namePost=='noticia'? 'Noticia' +' enviada correctamente': this.namePost == 'correo'?'Correo'+' enviado correctamente':'Evento'+' enviado correctamente'
        this.errorMessage = "Nuevo "+this.namePost+" enviado";
        this.submitbutonName = "Publicar"
        this.myArticle = new Article();
        this.myArticle.SchoolID = this.mySchool;
        this.btnclass = "btn-outline-primary";
        this._sweetalertService.swalSuccess(
          '',
          texto,
          ()=>{}
        )
        this.cleanInput()
      },
      error: ( err ) => {
        console.log( 'postNotificationQueue fail', err );
        this._sweetalertService.swalSuccess(
          '',
          'Correos programados para su envío',
          ()=>{}
        )
      }
    } );
  }

  viewModalContent(){
    this.showViewModal = !this.showViewModal
  }
  sendNews() {
    this.wellnessService.newArticle(this.myArticle).subscribe(
      p => {
        let texto = this.namePost=='noticia'? 'Noticia': this.namePost == 'correo'?'Correo':'Evento'
        this.errorMessage = `${this.namePost} cread${this.namePost == "Noticia" ? 'a' : 'o'}`;
        this.submitbutonName = "Publicar";
        this.btnclass = "btn-outline-primary";
        Swal.fire(
          '',
          `${texto} cread${this.namePost == "Noticia" ? 'a' : 'o'} correctamente`,
          'success'
        )
        if(this.principalAppName == 'celic'){
          this.notificationAdmin()
        }
        this.cleanInput()

      },
      e => {
        this.errorMessage = "No se pudo crear";
        this.submitbutonName = "Volver a intentar"
        Swal.fire('No se pudo crear', '', 'error')
      });
  }
  eliminaFoto(){
    this.myArticle.Imagen = ""
    this.DOC_FILE_NAME = ""
    this.fileUp = false
    this.inputFile.nativeElement.value = '';
  }
  createCalendarEvent() {
    this.fechaFinal = this.myArticle.Until
    // Crear un nuevo objeto Calendar
    const jcal = new ICAL.Component(['vcalendar', [], []]);
    // Crear un evento en el calendario
    const vevent = new ICAL.Component('vevent');
    // Configurar las propiedades del evento
    vevent.addPropertyWithValue('summary', this.myArticle.Title);
    //vevent.addPropertyWithValue('location', 'Sala de conferencias');
    this.myArticle.Description = this.myArticle.Description.replace(/"/g, "'")
    vevent.addPropertyWithValue('description', this.myArticle.Description.replace(/"/g, "'"));
    // const startDate = ICAL.Time.fromJSDate(new Date(`${this.myArticle.Until}T00:00:00Z`));
    // const endDate = ICAL.Time.fromJSDate(new Date(`${this.myArticle.Until}T23:59:00Z`));
    const startDate = ICAL.Time.fromJSDate(new Date(`${this.fechaInicio}`));
    const endDate = ICAL.Time.fromJSDate(new Date(`${this.fechaFinal}`));
    vevent.addPropertyWithValue('dtstart', startDate);
    vevent.addPropertyWithValue('dtend', endDate);
    // Agregar el evento al calendario
    jcal.addSubcomponent(vevent);
    // Serializar el objeto Calendar como texto ICS
    const icsData = jcal.toString();
    // Crear un Blob a partir del texto ICS y asignarlo a una variable File
    const blob = new Blob([icsData], { type: 'text/calendar' });
    const icsFile = new File([blob], 'evento.ics', { type: 'text/calendar' });
    // Ahora, `icsFile` es un archivo tipo File que contiene la información del evento en formato ICS
    this.icsFile = icsFile;
    this.uploadIcsFileDocuments();
  }

  bucketnameArticleInfo = environment.bucketnameArticleInfo;
  uploadFileDocuments(fileInput: any) {
      this.fileToUpload = <File>fileInput.target.files[0];
      if(this.tiposDocumentosPDF.includes(this.fileToUpload.type)){
        if (this.fileToUpload.size > 5000000) {//5M
          // this.errorMessage = 'Imagen no debe Superar 5MB'
          // this.btnclass = "btn-outline-danger"
        } else {
          if(this.tiposDocumentos.includes(this.fileToUpload.type)){
            this.validaImage = true
          }else{
            this.validaImage = false
          }
          this._sweetalertService.swalLoading('Cargando', 'Se está cargando el documento')
          this.wellnessService.articleImage(this.fileToUpload, this.bucketnameArticleInfo).then((result) => {
            if (!!result['name']) {
              this.myArticle.Imagen =  `https://storage.googleapis.com/${this.bucketnameArticleInfo}/${result['name']}`;
              this.DOC_FILE_NAME =  `${result['originalFilename']}`;
              this.btnclass = "btn-secundary";
              this._sweetalertService.swalSuccess('Archivo cargado', 'El archivo se ha cargado exitosamente', ()=>{});
              this.fileUp = true
            } else {
              this._sweetalertService.swalError('Error', 'No se pudo cargar el archivo', ()=>{});
            }
          }, (error) => {
            this._sweetalertService.swalError('Error', 'Error en archivo', ()=>{});
          })
        }
      }else{
        this._sweetalertService.swalError('Error', 'Archivo no permitido', ()=>{});
      }
  }
  uploadFileDocumentsIm(fileInput: any) {
    this.fileToUpload = <File>fileInput.target.files[0];
    if(this.tiposDocumentos.includes(this.fileToUpload.type)){
      if (this.fileToUpload.size > 5000000) {//5M
        // this.errorMessage = 'Imagen no debe Superar 5MB'
        // this.btnclass = "btn-outline-danger"
        this._sweetalertService.swalError('Error', 'Documento no debe superar 5MB', ()=>{});
      } else {
        this._sweetalertService.swalLoading('Cargando', 'Se está cargando el documento')
        this.wellnessService.articleImage(this.fileToUpload, this.bucketnameArticleInfo).then((result) => {
          if (!!result['name']) {
            this.myArticle.Imagen =  `https://storage.googleapis.com/${this.bucketnameArticleInfo}/${result['name']}`;
            this.DOC_FILE_NAME =  `${result['originalFilename']}`;
            this.btnclass = "btn-secundary";
            this._sweetalertService.swalSuccess('Archivo cargado', 'El archivo se ha cargado exitosamente', ()=>{});
            this.fileUp = true
          } else {
            this._sweetalertService.swalError('Error', 'No se pudo cargar el archivo', ()=>{});
          }
        }, (error) => {
          this._sweetalertService.swalError('Error', 'Error en archivo', ()=>{});
        })
      }
    }else{
      this._sweetalertService.swalError('Error', 'Archivo no permitido', ()=>{});
    }
  }

  uploadIcsFileDocuments() {
    if (this.icsFile.size > 5000000) {//5M
      // this.errorMessage = 'Documento no debe superar 5MB'
      // this.btnclass = "btn-outline-danger"
      this._sweetalertService.swalError('Error', 'Documento no debe superar 5MB', ()=>{});
    } else {
      this._sweetalertService.swalLoading('Cargando', 'Se está cargando el documento')
      this.wellnessService.articleImage(this.icsFile, this.bucketnameArticleInfo).then((result) => {
        if (!!result['name']) {
          this.ICS_FILE_URL = `https://storage.googleapis.com/${this.bucketnameArticleInfo}/${result['name']}`;
          this._sweetalertService.swalSuccess('Archivo cargado', 'El archivo .ICS se ha cargado exitosamente', ()=>{});
          
          this.sendMail();
        } else {
          this._sweetalertService.swalError('Error', 'No se pudo cargar el archivo .ICS', ()=>{});
        }
      }, (error) => {
        this._sweetalertService.swalError('Error', 'Error en archivo .ICS', ()=>{});
      })
    }

  }

  ICS_FILE_URL = '';
  DOC_FILE_NAME = '';

  notificationAdmin(){
    
    let urlSchool:string = `Schools/${this.myArticle.SchoolID}`
    this._httpService.httpGet(urlSchool).subscribe((data)=>{
      let emails
      let mensaje = `<br><br>Estimado equipo de Comunicaciones de la Dirección General de los Liceos del Ejército Nacional, reciban un cordial saludo. <br><br> Se les informa que ${this.myArticle.SchoolID==1?'la':'el'} ${data.NameTSchool}  ha realizado recientemente  la publicación de una nueva noticia titulada: <strong>${this.tituloNoticia}</strong> en la plataforma CELIC 360, la cual requiere su revisión y aprobación, con el objetivo que sea visible a la comunidad educativa del Liceo en mención. <br><br> Su pronta respuesta es de gran importancia para mantener informada a la comunidad educativa.`
      this.userService.getRoleMappingNotifacion(data.id).subscribe((res)=>{
        emails = res.map(user => user.userapp.email);
        let newDate: Date = new Date();
        let data = {
            emailsTo: `["${emails}"]`,
            namesTO: `["Nueva noticia publicada para revisión y aprobación"]`,
            msg: `[{"message":"${ mensaje }"}]`,
            timeToSend: newDate.toISOString(),
            isSend: false,
            isSingleMessage: true,
            typeNotification: 'Notificacion',
            senderName: `${this.currentUser.LastName1} ${this.currentUser.Name1}`,
            senderEmail: `${this.currentUser.email}`,
            atachment: null,
            templateFile: 'template.html'
          }
          let datas = []
          if (this.ICS_FILE_URL != '') datas.push({"name":"calendario.ics","url":this.ICS_FILE_URL}); 
          if (this.DOC_FILE_NAME != '') datas.push({"name":this.DOC_FILE_NAME,"url":this.myArticle.Imagen});
          data.atachment = JSON.stringify(datas).replace(/\\/g, "")          
          this.notificationQueMessage.push(data);
          this.postNotificQueue()
      })
    })
  }
}
