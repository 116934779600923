import { Component, OnInit } from '@angular/core';
import { FormInstrument } from 'src/app/models/formInstrument';
import { FormModule } from 'src/app/models/formModule';
import { FormField } from 'src/app/models/formField';
import { ActivatedRoute, Router } from '@angular/router';
import { FormfolderService } from 'src/app/services/formfolder.service';
import { School } from 'src/app/models/school';
import { IndexForm, TypeRole } from 'src/app/models/indexForm';
import { IndexFormUser } from 'src/app/models/indexFormUser';
import { CourseService } from 'src/app/services/course.service';
import { CoursesOfer } from 'src/app/models/coursesOfer';
import { Subjects } from 'src/app/models/subjects';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';
import { SubjectGroup } from 'src/app/models/subjectGroup';

@Component({
  selector: 'autoeval-template-index',
  templateUrl: './template-index.component.html',
  styleUrls: ['./template-index.component.css']
})
export class TemplateIndexComponent implements OnInit {


  public myFormInstrument: FormInstrument;
  public newIndexForm: IndexForm = new IndexForm();
  public newIndexFormUsers: IndexFormUser[];
  public usersList: { UserID: number }[];

  public courses: number[];
  public coursesOfers: CoursesOfer[];
  public courseOferSelected: CoursesOfer;
  public isActiveOfer: boolean = true;
  public subjects: Subjects[];
  public subjectSelected: Subjects;
  public subjectGroups: SubjectGroup[];
  public subjectGroupsSelected: SubjectGroup;
  public isData = false;
  public isAuth = false;
  public mySchool: number;

  public eTypeRole = TypeRole;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private courseService: CourseService,
    private formfolderService: FormfolderService
  ) {
    this.myFormInstrument = new FormInstrument();
    this.myFormInstrument.FormModules = [new FormModule()]
    this.myFormInstrument.FormModules[0].FormFields = [new FormField]
  }

  ngOnInit(): void {

    this.getRoles();

  }

  getRoles() {

    this.userService.getMyRoles().subscribe(
      roles => {
        roles.forEach(role => {
          if (role.role.name == "Calidad" || role.role.name == "Bienestar") {
            this.isAuth = true;
            this.mySchool = role.SchoolID;
          }
        });

        if (!this.isAuth) this.router.navigate(['/']);

        this.initForms()
      },
      error => {
        console.log(error);
        this.router.navigate(['/']);
      }
    )

  }

  initForms() {
    this.route.params.subscribe(params => {
      if (params['id']) {

        this.formfolderService.getFormInstrumentById(params['id']).subscribe(
          data => {
            this.myFormInstrument = data;
            this.newIndexForm.FormID = data.id;
            this.newIndexForm.NameIndexForm = this.myFormInstrument.NameForm;
            this.newIndexForm.TypeRole = TypeRole.Estudiante;
            this.newIndexForm.SchoolID = this.mySchool;
            this.isData = true;
          }
        );

      }
    });
  }

  setSchool(school: School) {
    this.newIndexForm.SchoolID = school.id;
  }

  setRole(role: TypeRole) {
    this.newIndexForm.TypeRole = role;
  }

  setUsers(users: { UserID: number }[]) {
    this.newIndexForm.Population = users.length;
    this.usersList = users;
  }

  setCourses(courses: number[]) {
    this.courses = courses;
    this.getCourseOfer();
  }

  onTypeOferChange() {
    this.getCourseOfer();
  }

  onCourseOferChange() {
    this.newIndexForm.CourseID = this.courseOferSelected.CourseID;
    this.newIndexForm.CourseOferID = this.courseOferSelected.id;
    this.newIndexForm.NameIndexForm = this.myFormInstrument.NameForm + " | " + this.courseOferSelected.NameCourseOfer;
    this.getSubjects();
  }

  onSubjectChange() {
    this.newIndexForm.SubjectID = this.subjectSelected.id;
    this.newIndexForm.NameIndexForm += " | " + this.subjectSelected.NameSubject;
  }

  onSubjectGrouphange() {
    this.newIndexForm.SubjectGroupID = this.subjectGroupsSelected.id;
    this.newIndexForm.NameIndexForm += " | " + this.subjectGroupsSelected.userapp.CedocEmail;
  }


  getCourseOfer() {
    this.courseService.getCoursesOfersByCourse(
      this.courses, this.isActiveOfer).subscribe(
        data => {
          this.coursesOfers = data;
        }
      );
  }

  getSubjects() {
    this.courseService.getSubjectsByCourse(
      [this.newIndexForm.CourseID]).subscribe(
        data => {
          this.subjects = data;
        }
      );
  }

  getSubjectsGroupsProfesor() {
    this.courseService.getSubjectGroups(
      [this.newIndexForm.SubjectID],true).subscribe(
        data => {
          this.subjectGroups = data;
        }
      );
  }

  saveIndexForm() {

    this.formfolderService.newIndexForm(this.newIndexForm).subscribe(
      data => {

        this.newIndexFormUsers = this.usersList.map(r => {
          let d: IndexFormUser = new IndexFormUser();
          d.UserID = r.UserID;
          d.IndexFormID = data.id;
          return d
        });
        this.formfolderService.newIndexFormUsers(this.newIndexFormUsers).subscribe(
          data => {
            Swal.fire('Correcto', 'Se envió correctamente la encuesta a' + this.newIndexForm.Population + ' usuarios', 'success');
          }
        );
      }
    );
  }

}