import { Component, OnInit, Input } from '@angular/core';
import { FormField, TypeQuestion } from 'src/app/models/formField';
import { FormfolderService } from 'src/app/services/formfolder.service';
import Swal from 'sweetalert2'



@Component({
  selector: 'autoeval-question-elm',
  templateUrl: './question-elm.component.html',
  styleUrls: ['./question-elm.component.css']
})

export class QuestionElmComponent implements OnInit {

  @Input()
  field: FormField;

  public isDelete = false;
  public eTypeQuestion = TypeQuestion;
  

  constructor(
    private formfolderService: FormfolderService
  ) {
    
   }

  ngOnInit(): void {
  }

  delete(){
    this.formfolderService.countFormFieldAnswers(this.field.id).subscribe(
      count => {
        console.log(count)

        if(count.count>0)
        {
          Swal.fire(
            'Error!',
            'La pregunta ya fue usada en una encuesta!',
            'error'
          )
        }
        else
        {
          this.formfolderService.deleteFormField(this.field.id).subscribe(
            data => {
              this.isDelete = true;
            }
          );
        }

      }
    );
  }

  editField(fileElement: FormField){

    this.formfolderService.editFormField(fileElement).subscribe(
      data => {
        Swal.fire(
          'Exito!',
          'Pregunta guardada correctamente!',
          'success'
        )
      }
    );
  }

}
