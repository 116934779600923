<div [ngClass]="{'d-none':isDelete}" class="question">
    
    <div class="question-main">

        <div class="pt-5">
            <input type="text" class="form-control" [(ngModel)]="field.NameField"  placeholder="Nombre de pregunta">
        </div>

        <div class="main-cont">
            
            <div class="options">
                <div *ngIf="field.TypeField == eTypeQuestion.opc1" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                                Se cumple plenamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                Se cumple en alto grado
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                Se cumple satisfactoriamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                Se cumple insatisfactoriamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                No se cumple
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc2" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                                Totalmente de Acuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                De acuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                Indiferente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                En Desacuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                Totalmente en Desacuerdo
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc3" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                                1
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                2
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                3
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                4
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                5
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc4" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                                Si
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                No
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
                                No Aplica
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div  *ngIf="field.TypeField == eTypeQuestion.opc5" class="">
                    <div class="form-group">
                        <label for="exampleTextarea">Pregunta Abierta</label>
                        <textarea class="form-control" id="exampleTextarea" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div class="butons">
                <select class="form-control"  [(ngModel)]="field.TypeField" >
                    <option [ngValue]="eTypeQuestion.opc1" >Cumplimiento 5 opciones</option>
                    <option [ngValue]="eTypeQuestion.opc2" >Concordancia 5 opciones</option>
                    <option [ngValue]="eTypeQuestion.opc3" >Cuantitativo 1-5</option>
                    <option [ngValue]="eTypeQuestion.opc4" >Si,No,No Aplica</option>
                    <option [ngValue]="eTypeQuestion.opc5" >Pregunta abierta</option>
                </select>

                <div class="last">
                    <button  (click)="editField(field)" class="btn btn-block btn-primary">Guardar</button>
                    <button (click)="delete()" class="btn btn-block btn-outline-primary">Eliminar</button>
                </div>
            </div>
        </div>

    </div>

    <div class="question-role">
        <h6 class="text-black mt-3 uppercase">
            <strong>Seleccione grupo para aplicar</strong>
        </h6>

        <div class="col text-center">
            <input type="number" [(ngModel)]="field.Percentage" name="" id="">
            <span> <strong> Porcentaje Pregunta </strong></span>
        </div>

        <div class="col">
            <input type="number" [(ngModel)]="field.PcIndividual" name="" id="">
            <span> Individual</span>
        </div>
        <div class="col">
            <input type="number" [(ngModel)]="field.PcAdmons" name="" id="">
            <span> Administrativos</span>
        </div>
        <div class="col">
            <input type="number" [(ngModel)]="field.PcProfesors" name="" id="">
            <span> Docentes</span>
        </div>
        <div class="col">
            <input type="number" [(ngModel)]="field.PcStudents" name="" id="">
            <span> Estudiantes</span>
        </div>
        <div class="col">
            <input type="number" [(ngModel)]="field.PcAlumni" name="" id="">
            <span> Egresados</span>
        </div>

    </div>

</div>