import { TypeRole } from './indexForm';

export class UserAnswer {
    public id:number;
    public IndexFormUserID:number;
    public FormFieldID:number;   
    public value:string;
    public CreatedAt:Date; 
    public TypeRole?:TypeRole;  
}
