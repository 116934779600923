import { MultiBar } from './multiBar';
import { UserAnswer } from './userAnswer';

export class FormField {
    public id:number;
    public FormID:number;
    public NameField:string;
    public TypeField:TypeQuestion;
    public IsDelete:boolean;
    public ModuleID:number;
    public Percentage:number;

    public PcAdmons:number;
    public PcProfesors:number;
    public PcStudents:number;
    public PcAlumni:number;
    public PcIndividual:number;

    public graph:MultiBar[];

    public UserAnswers:UserAnswer[];

}


export enum TypeQuestion {
    opc1 = 'opc1',
    opc2 = 'opc2', 
    opc3 = 'opc3', 
    opc4 = 'opc4',
    opc5 = 'opc5',  
  }
