import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(
    private _httpService: HttpService
  ) { }
  public clickSchoolId:number;
  
  getSchoolsAndChildren(schoolID:number,filter:string): Observable<School>{
    let url: string = `Schools/${schoolID}?filter={"include":["childrenSchools"${filter}]}`
    return this._httpService.httpGet(url);
  }

  getSchools(): Observable<School[]>{
    let url: string = `Schools`
    return this._httpService.httpGet(url);
  }
  getSchoolsbyFilters(filter: any): Observable<any> {
    let url = `Schools` 
    if(filter){
      url += `?filter=${filter}`
    }
    return this._httpService.httpGet(url);
    
  }
  
}
