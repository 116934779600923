import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurtainMenuComponent } from './curtain-menu/curtain-menu.component';
import { SurveyComponent } from './survey/survey.component';
import { QuestionOpcComponent } from './question-opc/question-opc.component';
import { UsersFilterComponent } from './users-filter/users-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from '../app-routing.module';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
 imports:      [ CommonModule, FormsModule, NgSelectModule, NgChartsModule],
 declarations: [ CurtainMenuComponent, SurveyComponent, QuestionOpcComponent, UsersFilterComponent ],
 exports:      [ CurtainMenuComponent, SurveyComponent, QuestionOpcComponent, UsersFilterComponent,
                 CommonModule, FormsModule ]
})
export class SharedModule { }