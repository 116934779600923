<section *ngIf="mySchool">

    <div class="card">

        <div class="card-header">
            <span class="cat__core__title centertext d-block">
                <strong>
                    SELECCIONA ESCUELA
                </strong>
            </span>
        </div>

        <div class="filterbar">
            <autoeval-curtain-menu [schoolID]="mySchool" (userSchoolChoice)="setSchool($event)"></autoeval-curtain-menu>
        </div>
    </div>

    <div class="card">

        <div class="card-header hd-blue">
            <h5 class="mb-0 text-black uppercase">
                <strong class="path-header" (click)="getFolderContent(folder.FolderID)">
                    SELECCIONA FORMULARIO
                    <i class="fa fa-angle-right"></i>
                    {{folder.Name}}
                </strong>
            </h5>
        </div>

        <div class="card-block">
            <div class="btn-group">
                <button type="button" (click)="menuNew()" class="btn btn-darkblue"> <i class="fa fa-plus"></i>
                    NUEVO</button>
                <button type="button" (click)="newFolder()" [ngStyle]="{'display': displayExp}"
                    class="btn btn-darkblue"> <i class="fa fa-folder-open-o"></i> Nueva Carpeta</button>
                <button type="button" (click)="newFile()" [ngStyle]="{'display': displayExp}" class="btn btn-darkblue">
                    <i class="fa fa-file-text-o"></i> Nuevo Formulario</button>
            </div>

            <div class="cardfolder">
                <h6 class="text-black mt-3 uppercase">
                    <strong>Carpetas</strong>
                </h6>
                <autoeval-folder-elm [elements]=folder (userFolderChoice)="getFolderContent($event)">
                </autoeval-folder-elm>
            </div>

            <div class="cardfile">
                <h6 class="text-black mt-3 uppercase">
                    <strong>Plantillas</strong>
                </h6>
                <autoeval-file-elm [elements]=folder (userFileChoice)="setFile($event)"></autoeval-file-elm>
            </div>

            <div *ngIf="!!fileSelected" class="text-center">
                <button (click)="deleteFile(fileSelected.id)"
                    class="btn btn-sm mr-5 btn-outline-danger ">Borrar</button>
                <a [routerLink]="['/autoeval/edit',fileSelected.id]"
                    class="btn btn-sm mr-2 btn-outline-primary ">Editar</a>
                <a [routerLink]="['/autoeval/index',fileSelected.id]" class="btn btn-sm btn-primary ">Usar</a>
            </div>
        </div>

    </div>
</section>

<section class="row">
    <div class="col-md-3">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0 text-black uppercase">
                    <strong>FILTROS</strong>
                </h5>
            </div>
            <div *ngIf="!!fileSelected" class="card-block">
                <div class="form-group">
                    <label class="">Elija tipo de oferta</label>
                    <select class="form-control" (change)="onTypeOferChange()" [(ngModel)]="isActiveOfer">
                        <option [ngValue]="true">Avtivas</option>
                        <option [ngValue]="false">Inactivas</option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="">Oferta</label>
                    <select class="form-control" [(ngModel)]="courseOferSelected" (change)="onCourseOferChange()">
                        <option *ngFor="let ofer of coursesOfers" [ngValue]="ofer">{{ofer.NameCourseOfer}}</option>
                    </select>
                </div>

                <div class="form-group ">
                    <label class="">Materia</label>
                    <select #Subject class="form-control" [(ngModel)]="subjectSelected" (change)="onSubjectChange()">
                        <option *ngFor="let subject of subjects" [ngValue]="subject">{{subject.NameSubject}}</option>
                    </select>
                </div>

                <div class="form-group ">
                    <label class="">Profesor</label>
                    <select class="form-control">
                        <option [ngValue]="'Individual'">Individual</option>
                        <option [ngValue]="'Estudiante'">Estudiante</option>
                        <option [ngValue]="'Egresado'">Egresado</option>
                        <option [ngValue]="'Docente'">Docente</option>
                        <option [ngValue]="'Administrativos'">Administrativos</option>
                    </select>
                </div>

            </div>
        </div>
    </div>

    <div class="col-md-9">
        <div class="card">

            <div class="card-header hd-blue">
                <h5 class="mb-0 text-black uppercase">
                    <strong>HISTÓRICOS</strong>
                </h5>
            </div>

            <div *ngIf="!!fileSelected" class="card-block">
                <div class="mb-5">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Población</th>
                                <th>Nombre Encuesta</th>
                                <th>Fecha Encuesta</th>
                                <th>Personas Encuestadas</th>
                                <th>Promedio</th>
                                <th>
                                    <span *ngIf="!consolidated.isBtn">
                                        Resultados Consolidados
                                    </span>
                                    <a *ngIf="consolidated.isBtn" [routerLink]="['/autoeval/dashboard']" 
                                    [queryParams]="{ instrument :consolidated.FormID,  Forms: consolidated.ids }"class="btn btn-bluedark">
                                         <i class="fa fa-bar-chart"></i> Consolidados</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let indexForm of indexForms ; index as i">
                                <th scope="row">
                                    <input (change)="onToggle($event,indexForm.id,indexForm.FormID)" type="checkbox" />
                                </th>
                                <td>{{indexForm.TypeRole}}</td>
                                <td>{{indexForm.NameIndexForm}}</td>
                                <td>{{indexForm.Since | date}} a {{indexForm.Until | date}}</td>
                                <td>{{indexForm.IndexFormUsers?.length}}/{{indexForm.Population}}</td>
                                <td>{{indexForm.Percentage | number : '1.2'}}%</td>
                                <td>
                                    <a [routerLink]="['/autoeval/dashboard']" 
                                    [queryParams]="{ instrument :indexForm.FormID,  Forms: [indexForm.id] }"class="btn btn-darkblue">
                                         <i class="fa fa-bar-chart"></i> Resultados</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</section>