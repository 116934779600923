export class School {
    public id:number;
    public NameTSchool:string;
    public SchoolAcronim:string;
    public DepenSchoolID: number;
    public ReportSchool: string;
    public Icono:string;
    public UrlImage:string;
    public Color: string;

    public parentSchool: School;
    public childrenSchools: School[];
    
}
