
import { FormField } from './formField';
import { SeriesData } from './multiBar';

export class FormModule {
    public id:number;
    public FormID:number;
    public NameModule:string;
    public Color:string;
    public Percentage:number;
    public isEdit:boolean;
    public graph?:SeriesData[];
    public FormFields:FormField[];
}
