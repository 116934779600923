import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { CareArea } from '../models/careArea';
import { Article } from '../models/article';
import { School } from '../models/school';

@Injectable({
  providedIn: 'root'
})
export class WellnessService {

  constructor(
    private _httpService: HttpService
  ) { }

  getCareAreaBySchool(schools:number[]): Observable<CareArea[]>{
    let url: string = `CareAreas?filter={"where": {"SchoolID": {"inq": [${schools}]}}}`;
    return this._httpService.httpGet(url);
  }

  getArticleBySchool(schools:number[]): Observable<Article[]>{
    let url: string = `Articles?filter={"order": "id DESC","limit": 20 ,"include":["careArea"],"where": {"SchoolID": {"inq": [${schools}]}}}`;
    return this._httpService.httpGet(url);
  }

  getArticleByID(id:number): Observable<Article>{
    let url: string = `Articles/${id}`;
    return this._httpService.httpGet(url);
  }

  deleteArticleByID(id:number) {
    let url: string = `Articles/${id}`;
    return this._httpService.httpDelete(url);
  }
  UpdateArticleByID(id:number, data:any) {
    let url: string = `Articles/${id}`;
    return this._httpService.httpPatch(url, data);
  }
  newEmail(users:string[], article:Article): Observable<Article>{
    const data = {
      subject:article.Title,
      content:article.Description,
      users:users
    }
    let url: string = "CareAreas/sendemail";
    return this._httpService.httpPost(url,data);
  }
  postNotificationQueue(data): Observable<any>{
    let url: string = "NotificationQueues";
    return this._httpService.httpPost(url,data);
  }

  newArticle(data:Article): Observable<Article> {
    let url: string = "Articles";
    return this._httpService.httpPost(url,data);
  }

  articleImage(file: File, bucketName: string) {
    return this._httpService.uploadFile(file, bucketName);
  }
  
}
