import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  public urlHttp: string = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthService) { 
    
  }

  getHeader(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  httpGet(url: string): any {
    return this.http.get(this.urlHttp + url, {headers: this.getHeader()} );
  }
  httpPost(url: string, data: any): any {
    return this.http.post(this.urlHttp + url, data, {headers: this.getHeader()} );
  }
  httpDelete(url: string): any {
    return this.http.delete(this.urlHttp + url, {headers: this.getHeader()} );
  }
  httpPatch(url: string, data: any): any {
    return this.http.patch(this.urlHttp + url, data, {headers: this.getHeader()} );
  }

  uploadFile(file: File, buket: string) {

    return new Promise((resolve, reject) => {
  
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
  
      xhr.open("POST", `${this.urlHttp}/attachments/${buket}/upload`, true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var res = JSON.parse(xhr.responseText)
          resolve(res.result.files.file[0]);
        }
      };
  
      fd.append('file', file);
      xhr.setRequestHeader('Authorization', this.authService.getToken())
      xhr.send(fd);
  
    });
  
  }

}
