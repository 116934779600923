import { Component, OnInit, Input } from '@angular/core';
import { FormField, TypeQuestion } from 'src/app/models/formField';

@Component({
  selector: 'autoeval-question-opc',
  templateUrl: './question-opc.component.html',
  styleUrls: ['./question-opc.component.css']
})
export class QuestionOpcComponent implements OnInit {

  @Input()
  field: FormField;

  public isDelete = false;
  public eTypeQuestion = TypeQuestion;
  

  
  constructor() { }

  ngOnInit(): void {
  }

}
