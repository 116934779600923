import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;

  constructor() { }

  getUser() {
    let userID = parseInt(localStorage.getItem('currentUser'));
    if (isNaN(userID)) { return null; }
    return userID;
  }

  getToken() {
    let token = localStorage.getItem('accessToken');
    this.token = null;
    if (token) {
      this.token = token;
    }
    return this.token;
  }

  setToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

}

