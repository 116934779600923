import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormfolderService } from 'src/app/services/formfolder.service';
import { FormFolder } from 'src/app/models/formFolder';
import { FormInstrument } from 'src/app/models/formInstrument';
import { School } from 'src/app/models/school';
import Swal from 'sweetalert2'
import { IndexForm } from 'src/app/models/indexForm';
import { CoursesOfer } from 'src/app/models/coursesOfer';
import { Subjects } from 'src/app/models/subjects';
import { CourseService } from 'src/app/services/course.service';
import { IndexFormUser } from 'src/app/models/indexFormUser';
import { UserService } from 'src/app/services/user.service';
// import { ThrowStmt } from '@angular/compiler'; // TODO: DAVID - no se usa???
@Component({
  selector: 'autoeval-template-main',
  templateUrl: './template-main.component.html',
  styleUrls: ['./template-main.component.css']
})
export class TemplateMainComponent implements OnInit {

  public mySchool: number;
  public myFolder: number;
  public relatedSchool: number[];

  public folder: FormFolder = new FormFolder();
  public fileSelected: FormInstrument;

  public indexForms: IndexForm[] = [new IndexForm()];
  public indexFormsTotal: [IndexForm];

  public courses: number[];
  public coursesOfers: CoursesOfer[];
  public courseOferSelected: CoursesOfer;
  public isActiveOfer: boolean = true;
  public subjects: Subjects[];
  public subjectSelected: Subjects;
  public isData = false;

  public consolidated: { isBtn: boolean, FormID: number, ids: number[] } = { isBtn: false, FormID: 0, ids: [] }

  public displayExp = "none";
  public isAuth = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private userService: UserService,
    private formfolderService: FormfolderService
  ) {
    this.indexForms[0].FormInstrument = new FormInstrument();
    this.indexForms[0].IndexFormUsers = [new IndexFormUser()];
  }

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles() {

    this.userService.getMyRoles().subscribe(
      roles => {
        roles.forEach(role => {
          if (role.role.name == "Calidad") {
            this.isAuth = true;
            this.mySchool = 25;
          }
          if (role.role.name == "Bienestar") {
            this.isAuth = true;
            this.mySchool = role.SchoolID;
          }
        });

        if (!this.isAuth) this.router.navigate(['/']);

        this.initFolders()
      },
      error => {
        console.log(error);
        this.router.navigate(['/']);
      }
    )

  }

  initFolders() {
    this.route.queryParams.subscribe(params => {
      this.myFolder = params['folder'];
      if (!!this.myFolder)
        this.resumeFolderContent(this.myFolder);
      else
        this.getMySchool()
    });
  }

  menuNew() {
    this.displayExp = this.displayExp == "none" ? "" : "none";
  }

  getMySchool() {
    if (!this.myFolder) {
      this.myFolder = this.mySchool
      this.getFolderContent(this.myFolder);
    }
  }


  //***File system functions */
  setSchool(school: School) {
    this.mySchool = school.id;
    this.relatedSchool = [this.mySchool, school.DepenSchoolID]
    this.getFolderContent(this.mySchool);
  }

  setFile(formInstrument: FormInstrument) {
    this.fileSelected = formInstrument;
    this.formfolderService.getIndexFormByFormID(formInstrument.id).subscribe(
      f => {
        this.indexFormsTotal = f;
        this.indexFormsTotal.forEach((indFo, i, arr) => {
          let totAnswer: IndexFormUser[] = indFo.IndexFormUsers.filter(r => r.IsAnswer == true);
          if (!!totAnswer) {
            indFo.Population = totAnswer.length;
          }
          else {
            indFo.Population = 0;
          }

        });
        this.indexForms = this.indexFormsTotal;
        this.setCourses(
          f.reduce(function (r, e) {
            if (e.CourseID)
              r.push(e.CourseID)
            return r;
          }, [])
        );
      }
    );
  }

  resumeFolderContent(FolderID: number) {
    this.fileSelected = null;
    this.myFolder = FolderID;
    this.formfolderService.getformInstrumentsInFolder(FolderID).subscribe(
      f => {
        this.folder = f;
        this.mySchool = f.SchoolID;
      }
    );
  }

  getFolderContent(FolderID: number) {
    this.fileSelected = null;
    this.myFolder = FolderID;
    this.formfolderService.getformInstrumentsInFolder(FolderID).subscribe(
      f => {
        this.folder = f;
      }
    );
  }

  newFolder() {
    Swal.fire({
      title: 'Nombre De La Carpeta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Crear',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {

        let formIFolderData = new FormFolder();
        formIFolderData.Name = name;
        formIFolderData.SchoolID = this.mySchool;
        formIFolderData.FolderID = this.myFolder;

        this.formfolderService.newFormFolder(formIFolderData).subscribe(
          d => {
            this.getFolderContent(this.myFolder);
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {

      if (result.isConfirmed)
        Swal.fire(
          'Exito!',
          'Plantilla creada correctamente!',
          'success'
        )

    })
  }

  newFile() {
    Swal.fire({
      title: 'Nombre De La Plantilla',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Crear',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {

        let formInstrumentData = new FormInstrument();


        formInstrumentData.NameForm = name;
        formInstrumentData.Descript = null;
        formInstrumentData.SchoolID = this.mySchool;
        formInstrumentData.FormFolderID = this.myFolder;


        this.formfolderService.newFormInstrument(formInstrumentData).subscribe(
          data => {
            this.editFile(data.id)
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed)
        Swal.fire(
          'Exito!',
          'Plantilla creada correctamente!',
          'success'
        )

    })
  }

  editFile(formInstrumentID: number) {
    this.router.navigate([`autoeval/edit/${formInstrumentID}`]);
  }

  deleteFile(formInstrumentID: number) {
    this.formfolderService.deleteFormInstrument(formInstrumentID).subscribe(
      f => {
        Swal.fire(
          'Exito!',
          'Plantilla Borrada!',
          'success'
        )
      },
      e => {
        Swal.fire(
          'Error!',
          'No se puede borrar',
          'error'
        )
      }
    );
  }

  //***Index functions */

  setCourses(courses: number[]) {
    this.courses = courses;
    this.getCourseOfer();
  }

  onTypeOferChange() {
    this.getCourseOfer();
  }

  onCourseOferChange() {
    this.indexForms = this.indexFormsTotal.filter(r => r.CourseOferID == this.courseOferSelected.id);
    this.getSubjects();
  }

  onSubjectChange() {
    this.indexForms = this.indexFormsTotal.filter(r => r.SubjectID == this.subjectSelected.id);
  }

  getCourseOfer() {
    this.courseService.getCoursesOfersByCourse(
      this.courses, this.isActiveOfer).subscribe(
        data => {
          this.coursesOfers = data;
        }
      );
  }

  getSubjects() {
    this.courseService.getSubjectsByCourse(
      [this.courseOferSelected.CourseID]).subscribe(
        data => {
          this.subjects = data;
        }
      );
  }

  getSubjectsGroupsProfesor() {
    this.courseService.getSubjectsByCourse(
      [this.courseOferSelected.CourseID]).subscribe(
        data => {
          this.subjects = data;
        }
      );
  }

  onToggle(e, id, FormID) {
    if (e.target.checked) {
      this.consolidated.ids.push(id);
      this.consolidated.FormID = FormID;
      this.consolidated.isBtn = true;
    }
    else {
      this.consolidated.ids = this.consolidated.ids.filter(e => { e != id });
      if (this.consolidated.ids.length < 1)
        this.consolidated.isBtn = false
    }

  }

}
