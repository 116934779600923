import { Component, OnInit } from '@angular/core';
import { WellnessService } from 'src/app/services/wellness.service';
import { Article, TypeArticle } from 'src/app/models/article';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/services/school.service';
import { School } from 'src/app/models/school';
import { UserService } from 'src/app/services/user.service';
import { RoleMapping } from 'src/app/models/roleMapping';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { CareArea } from 'src/app/models/careArea';
import { HttpService } from 'src/app/services/http.service';
import { forkJoin } from 'rxjs';
import { UserApp } from 'src/app/models/userApp';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'autoeval-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public myArticles : Article[];
  
  public notificationQueMessage = []
  public principalAPPNAME = environment.principalAppName.toLowerCase();
  public schools: School[];
  public schoolSelected: School[];
  public fileToUpload: File;
  public errorMessage: string = '';
  public btnclass = "btn-outline-primary"
  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo' ]
    ]
  };

  DOC_FILE_NAME = '';
  articleId:number
  user: number;
  roles: RoleMapping[];
  showDeleteBtn: boolean = false;
  publicarArticleBtn: boolean = false;
  public currentUser: UserApp;
  public myArticle: Article = new Article();
  public showViewModal = false;
  public showViewModalEdit = false
  public categories: CareArea[] = [new CareArea()];
  public categoriesSelect :String = ''
  htmlContent: SafeHtml;
  public isAuth = false;
  public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
  public SCHOOL_PHOTO_DYNAMIC:any = (imageName:string) => environment.SCHOOL_PHOTO_DYNAMIC(imageName);
  bucketnameArticleInfo = environment.bucketnameArticleInfo;
  constructor(
    private route: ActivatedRoute,
    private wellnessService: WellnessService,
    private schoolService: SchoolService,
    private _userService: UserService,
    private _sweetalertService: SweetalertService,
    private _router: Router,
    private _httpService: HttpService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {

    this.myArticles = [new Article()];
    this.schools = [new School()]
    this.schoolSelected = [new School()]

    this._userService.getMyRoles().subscribe(p => {
      this.roles = p;
      let schools = this.roles.map(x=> x.school).map(y => y.id )
      let isSameSchool = schools.every(val => val == schools[0] )
      if(!isSameSchool){
          this._sweetalertService.swalError('Error', 
          'Se encuentra con roles duplicados, contactese con su administrador para validar sus roles', 
          ()=>{this._router.navigate(['/user/my-account']);});
        return
      }

        let filter = {include:{childrenSchools:"childrenSchools"}, where:{id:schools[0]} , order:"NameTSchool ASC"}
        this.schoolService.getSchoolsbyFilters(JSON.stringify(filter)).subscribe(res=>{
          this.schools =  this.flattenArray(res);
        });
        

      for (let role of this.roles) {

        this.user = role.principalId
        // 1 Admin - 20 Bienestar  1 school Direccion General CELIC
        if (role.role.id == 1 || role.role.id == 20 || role.school.id == 1) this.showDeleteBtn = true;
        if (this.principalAPPNAME == 'cedoc' && role.school.id==25 ) this.publicarArticleBtn = true; 
        if (this.principalAPPNAME == 'celic' && role.school.id == 1) 
          {this.showDeleteBtn = true; this.publicarArticleBtn = true};
        if (role.role.id == 23 ) this.showDeleteBtn = true;
      }
    })
   }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.schoolSelected=this.schools.filter( x => { x.id = params['id']});
      }
      else{
        this.schoolSelected=this.schools;
      }          
      this.onSchoolChange(this.schoolSelected);
    });
    this.getCategories()
    this.getRoles()
  }
  public navigate() {
    this._router.navigate(['wellness/noticia/new']);
  }
  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  getCategories() {
    this.wellnessService.getCareAreaBySchool([25]).subscribe(
      data => {
        this.categories = data;
      }
    );
  }
  getRoles() {
    this._userService.getMyRoles().subscribe(
      roles => {
        roles.forEach(role => {
          if (
            role.role.name == "Bienestar" || 
            role.role.name == "Extensión Egresado" || 
            role.role.name == "Egresados" || 
            role.role.name == "Extensión" || 
            role.role.name == "Biblioteca"
          ) {
            this.isAuth = true;
            this.myArticle.SchoolID = role.SchoolID;
            this.currentUser = role.userapp
          }
        });
        if (!this.isAuth) this.router.navigate(['/']);
      },
      error => {
        console.log(error);
        this.router.navigate(['/']);
      }
    )
  }
  convertToDateOnly(article: Date) {
    if(article){
      const datePart =  article?.toString().split('T')[0];
      return datePart;
    }
  }
  viewModalContent(){
    this.showViewModal = !this.showViewModal
  }
  viewModalContentEdit(){
    this.showViewModalEdit = !this.showViewModalEdit
  }
  uploadFileDocuments(fileInput: any) {

    this.fileToUpload = <File>fileInput.target.files[0];
    if (this.fileToUpload.size > 5000000) {//5M
      this.errorMessage = 'Imagen no debe Superar 5MB'
      this.btnclass = "btn-outline-danger"
    } else {
      this._sweetalertService.swalLoading('Cargando', 'Se está cargando el documento')
      this.wellnessService.articleImage(this.fileToUpload, this.bucketnameArticleInfo).then((result) => {
        if (!!result['name']) {
          this.myArticle.Imagen =  `https://storage.googleapis.com/${this.bucketnameArticleInfo}/${result['name']}`;
          console.log("this.myArticle.Imagen ", this.myArticle.Imagen)
          this.myArticle.Imagen = this.myArticle.Imagen
          this.DOC_FILE_NAME =  `${result['originalFilename']}`;
          this.btnclass = "btn-secundary";
          this._sweetalertService.swalSuccess('Archivo cargado', 'El archivo se ha cargado exitosamente', ()=>{});
        } else {
          this._sweetalertService.swalError('Error', 'No se pudo cargar el archivo', ()=>{});
        }
      }, (error) => {
        this._sweetalertService.swalError('Error', 'Error en archivo', ()=>{});
      })
    }
  }
  onSchoolChange(schools:School[]){

    this.schoolSelected=schools
    this.wellnessService.getArticleBySchool(schools.map( d => d.id )).subscribe(
      data => {
        this.myArticles=data;
      }
    );
  }
  
  flattenArray(arr) {
    return arr.map(item => {
        if (item.childrenSchools && item.childrenSchools.length > 0) {
            return [item, ...this.flattenArray(item.childrenSchools)];
        } else {
            return item;
        }
    }).flat();
  }


  deleteArticle(article: Article, index: number): void {
    this._sweetalertService.swalConfirm('Eliminar', '¿Está seguro de eliminar la noticia?').then( ( result ) => {
      if(result.isConfirmed){
        this.wellnessService.deleteArticleByID(article.id).subscribe(
          data => {
            this._sweetalertService.swalSuccess('Eliminado', 'Noticia eliminada', ()=> {})
            this.myArticles.splice(index, 1);   
          }
        );
      }
    })
  }
  mostrarPublicacion(article: Article, index: number): void{
    this.showViewModal = !this.showViewModal,
    this.myArticle.Title = article.Title
    this.myArticle.Description = article.Description
    this.myArticle.Imagen = article.Imagen
    this.categoriesSelect = article.careArea.CareAreaName
    this.myArticle.linkArticle = article.linkArticle
  }
  EditarPublicacion(article: Article, index: number): void{
    this.showViewModalEdit = !this.showViewModalEdit,
    this.myArticle.Title = article.Title
    this.myArticle.Description = article.Description
    this.myArticle.Imagen = article.Imagen
    this.categoriesSelect = article.careArea.CareAreaName
    this.myArticle.linkArticle = article.linkArticle
    this.articleId = article.id
  }
  PublicarArticle(article: Article, index: number): void {
    let value = article.isApproved
    this._sweetalertService.swalConfirm('Aprobar', '¿Está seguro de aprobar la noticia?').then(( result ) => {
      if(result.isConfirmed){
        article.isApproved = !article.isApproved
        article.approvedBy = this.user;
        this.wellnessService.UpdateArticleByID(article.id, article).subscribe(()=>{
          if(this.principalAPPNAME =='celic'){   
            this.notificationAdmin(article)
          }
        }
        );
      }else
        this.onSchoolChange(this.schoolSelected)
    })
  }

  PrivateArticle(article: Article, index: number): void {
    article.IsPrivate = !article.IsPrivate
    article.approvedBy = this.user;
    this.wellnessService.UpdateArticleByID(article.id, article).subscribe(
    );
  }
  GuardarCambios(article: Article,){
    this.wellnessService.UpdateArticleByID(this.articleId, article).subscribe(res=>{
      this._sweetalertService.swalSuccess('Noticia actualizada', 'Se actualizó correctamente la noticia', ()=>{
      });
      this.showViewModalEdit = !this.showViewModalEdit
      this.onSchoolChange(this.schoolSelected)
    })
    ;
  }
  notificationAdmin(article:Article){
    if(article.isApproved){
      this.notificationQueMessage = []
      let urlSchool:string = `Schools/${article.SchoolID}`
      this._httpService.httpGet(urlSchool).subscribe((data)=>{
        let emails
        let mensaje = `<br><br>Estimado equipo de comunicación ${data.NameTSchool}, <br><br>
        Nos complace informarles que la noticia titulada ${article.Title} ha sido revisada y aprobada en la plataforma CELIC 360 y se encuentra visible para la comunidad educativa.<br><br>
        Agradecemos su valiosa contribución al mantener informada a nuestra comunidad educativa.<br><br>
        `
        this._userService.getRoleMappingNotifacion(data.id).subscribe((res)=>{
          emails = res.map(user => user.userapp.email);
          let newDate: Date = new Date();
          let data = {
            emailsTo: `["${emails}"]`,
            namesTO: `["Publicación de noticia revisada y aprobada"]`,
            msg: `[{"message":"${ mensaje }"}]`,
            timeToSend: newDate.toISOString(),
            isSend: false,
            isSingleMessage: true,
            typeNotification: 'Notificacion',
            senderName: `${this.currentUser?.LastName1} ${this.currentUser?.Name1}`,
            senderEmail: `${this.currentUser?.email}`,
            atachment: null,
            templateFile: 'template.html'
          }
          let datas = []
          data.atachment = JSON.stringify(datas).replace(/\\/g, "")          
          this.notificationQueMessage.push(data);
          this.wellnessService.postNotificationQueue( this.notificationQueMessage ).subscribe(res =>{
          })
        })
      })
    }
  } 
}
