import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormFolder } from 'src/app/models/formFolder';

@Component({
  selector: 'autoeval-folder-elm',
  templateUrl: './folder-elm.component.html',
  styleUrls: ['./folder-elm.component.css']
})
export class FolderElmComponent implements OnInit {
  @Input()
  elements: FormFolder;
  @Output()
  userFolderChoice = new EventEmitter<Number>();

  constructor() { }

  ngOnInit(): void {
  }

  setFolder(folderID){
    this.userFolderChoice.emit(folderID);
  }

}
