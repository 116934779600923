import { CareArea } from './careArea';

export class Article {
    public id:number;
    public SchoolID:number;
    public CareAreaID:number;
    public IsPrivate:boolean;
    public Title:string;
    public Summary:string;
    public Description:string;
    public Imagen:string;
    public linkArticle:string;
    public isApproved:boolean;
    public approvedBy:number;
    public Until:Date;
    public CreateAt:Date;
    public careArea:CareArea;
}

export enum TypeArticle {
    email = 'email',
    news = 'news', 
    article = 'article', 
    event = 'event', 
  }
