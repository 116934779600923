import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/school';
import { Role, RoleMapping } from '../models/roleMapping';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _httpService: HttpService
  ) { }


  getForce(): Observable<School>{
    let url: string = `Forces`
    return this._httpService.httpGet(url);
  }
  getRangeByForce(filter:string): Observable<School>{
    let url: string = `Ranges?filter={"where": {"ForceID": {"inq": [${filter}]}}}`
    return this._httpService.httpGet(url);
  }
  getrole(): Observable<Role[]>{
    let url: string = `roles`
    return this._httpService.httpGet(url);
  }
  getStudents(data): Observable<any>{
    let url: string = `Userapps/vewStudents`
    return this._httpService.httpPost(url,data);
  }
  getRoleMappingNotifacion(id): Observable<any>{
    let url: string = `roleMappings?filter={"where":{"and":[{"roleId":20},{"SchoolID":${id}}]},"include":["userapp"]}`;
    return this._httpService.httpGet(url);
  }
  getStudentsDataFromAcudentsData(data): Observable<any>{
    let url: string = `Userapps/viewStudentsAceptedInUserCourse`
    return this._httpService.httpPost(url,data);
  }
  getAdmins(data): Observable<any>{
    let url: string = `Userapps/vewAdmins`
    return this._httpService.httpPost(url,data);
  }
  getTeachers(data): Observable<any>{
    let url: string = `Userapps/vewTeachers`
    return this._httpService.httpPost(url,data);
  }
  getMyRoles(): Observable<RoleMapping[]> {
    let url: string = `Userapps/me/roleMappings?filter={"include": ["school","role","userapp"]}`
    return this._httpService.httpGet(url);
  }
  getUserAcudientes(allStudentsIds: number[]): Observable<any[]> {
    let url: string = `/ParentsToStudents?filter={"where": {"StudentUserId": {"inq":[${allStudentsIds}]}}, "include": ["parentUser"]}`
    return this._httpService.httpGet(url);
  }
  
}
