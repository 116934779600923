import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WellnessService } from 'src/app/services/wellness.service';
import { Article } from 'src/app/models/article';
import { CareArea } from 'src/app/models/careArea';
import { School } from 'src/app/models/school';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'autoeval-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  public myArticle : Article;
  public schools: School[];
  public schoolSelected: School[];

  constructor(
    private route: ActivatedRoute,
    private wellnessService: WellnessService,
    private schoolService: SchoolService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        this.wellnessService.getArticleByID(params['id']).subscribe(
          data => {
            this.myArticle=data;
          }
        );
        
      }
    });
    this.getFilters();
  }

  getFilters(){
      this.schoolService.getSchools().subscribe(
        data => {
          this.schools=data;
          this.schoolSelected = data;
        }
      )
    
  }


}
