import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormInstrument } from 'src/app/models/formInstrument';
import { FormfolderService } from 'src/app/services/formfolder.service';
import { FormModule } from 'src/app/models/formModule';
import { FormField, TypeQuestion } from 'src/app/models/formField';
import Swal from 'sweetalert2'
@Component({
  selector: 'autoeval-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.css']
})
export class TemplateEditComponent implements OnInit {

  myFormInstrument: FormInstrument
  
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private formfolderService: FormfolderService
    ) {

      this.myFormInstrument = new FormInstrument();
      this.myFormInstrument.FormModules = [ new FormModule()]
      this.myFormInstrument.FormModules[0].FormFields = [new FormField]
     }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        this.formfolderService.getFormInstrumentById(params['id']).subscribe(
          data => {
            this.myFormInstrument=data;
          }
        );
        
      }
    });
  }

  newField(moduleID, index,){

    Swal.fire({
      title: 'Texto De La Pregunta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Crear',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {

        let newField = new FormField();
        newField.NameField=name;
        newField.ModuleID=moduleID;
        newField.TypeField=TypeQuestion.opc1;
      
        
        this.formfolderService.newFormField(newField).subscribe(
          data => {
            this.myFormInstrument.FormModules[index].FormFields.push(data);
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed)
        Swal.fire(
          'Exito!',
          'Pregunta creada correctamente!',
          'success'
        )
      
    })
  }

  newModule(){

    Swal.fire({
      title: 'Nombre De La Condición',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Crear',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {

        let newModule = new FormModule;
        newModule.Color="color";
        newModule.FormID=this.myFormInstrument.id;
        newModule.NameModule=name;
        
        this.formfolderService.newFormModule(newModule).subscribe(
          data => {
            data.FormFields = []
            this.myFormInstrument.FormModules.push(data);
          }
        );

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      
      if (result.isConfirmed)
        Swal.fire(
          'Exito!',
          'Condición creado correctamente!',
          'success'
        )
      
    })

  }

  editTemplate(formInstrument: FormInstrument){
    this.formfolderService.editFormInstrument(formInstrument).subscribe(
      data => {
        Swal.fire(
          'Exito!',
          'Plantilla modificada correctamente!',
          'success'
        )
        this.router.navigate(['/autoeval'], { queryParams: { folder: formInstrument.FormFolderID } });
      }
    );
  }

  editModule(moduleElement: FormModule){

    this.formfolderService.editFormModule(moduleElement).subscribe(
      data => {
        moduleElement.isEdit = false;
      }
    );

  }
}
