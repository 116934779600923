import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormFolder } from 'src/app/models/formFolder';
import { FormInstrument } from 'src/app/models/formInstrument';

@Component({
  selector: 'autoeval-file-elm',
  templateUrl: './file-elm.component.html',
  styleUrls: ['./file-elm.component.css']
})
export class FileElmComponent implements OnInit {
  
  @Input()
  elements: FormFolder;
  @Output()
  userFileChoice = new EventEmitter<FormInstrument>();

  public fileActiveID : number
  constructor() { }

  ngOnInit(): void {
  }

  setFile(formInstrument){
    this.fileActiveID = formInstrument.id
    this.userFileChoice.emit(formInstrument);
  }
}
