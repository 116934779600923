<section *ngIf="isDataReady">
    <div class="card">
      <div class="card-header">
          <span class="cat__core__title text-center d-block">
              <strong>
                  Instrumento: {{myIndexFormUser.IndexForm.NameIndexForm}}
              </strong>
          </span>
      </div>
    </div>

    <div  *ngFor="let module of myIndexFormUser.IndexForm.FormInstrument.FormModules; let i = index" class="card">
        <div class="card-header hd-blue">
            <h5 class="text-black float-left uppercase">
                <strong>{{module.NameModule}}</strong> 
            </h5>
        </div>
        <div *ngFor="let field of module.FormFields" class="card-block">
            <autoeval-survey [role]="myIndexFormUser.IndexForm.TypeRole" [field]="field" (value)="setValue($event,field.id)" ></autoeval-survey>
        </div>
    </div>


    <div class="card">
        <div class="card-header">
            <span class="cat__core__title  text-center d-block">
                <button (click)="saveInstrument()" class="btn btn-sm btn-primary">Enviar</button>
            </span>
        </div>
    </div>
    
</section>

<section *ngIf="!isDataReady">
    <div class="card">
      <div class="card-header">
          <span class="cat__core__title text-center d-block">
              <strong>
                  Esta encuesta ya tienene respuestas guardadas 
              </strong>
          </span>
      </div>
    </div>
</section>