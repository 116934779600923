import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Category } from '../models/catergory';
import { Modality } from '../models/modality';
import { Course } from '../models/course';
import { CoursesOfer } from '../models/coursesOfer';
import { AcademicArea } from '../models/academicArea';
import { Subjects } from '../models/subjects';
import { SubjectGroup } from '../models/subjectGroup';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private _httpService: HttpService
  ) { }


  getCategories(): Observable<Category[]>{
    let url: string = `Categories?filter={"include":["TypeCourses"]}`
    return this._httpService.httpGet(url);
  }

  getModalities(): Observable<Modality[]>{
    let url: string = `Modalities`
    return this._httpService.httpGet(url);
  }
  
  getCourses(filter?:string): Observable<Course[]>{
    let url: string = `Courses`
    if(!!filter)
      url = url + "?filter=" + filter
    return this._httpService.httpGet(url);
  }
  getArticleFilter(filter:string): Observable<any>{
    let url: string = `Articles/count`
    if(!!filter)
      url = url + "?where=" + filter
    return this._httpService.httpGet(url);
  }
  getNotificationsFilter(filter:string): Observable<any>{
    let url: string = `NotificationQueues/count`
    if(!!filter)
      url = url + "?where=" + filter
    return this._httpService.httpGet(url);
  }

  getCoursesOfers(schools:number[],active:boolean,year:number): Observable<CoursesOfer[]>{
    let url: string = ''
    let year1 = new Date(year, 0);
    let year2 = new Date(year + 1, 0);

    if (active)
      url = `CoursesOfers?filter={"include":"coursegroups","where":{"and":[{"IsActive":${active}}, {"SchoolID":{"inq":[${schools}]}} ]} }`
    else
      url = `CoursesOfers?filter={"include":"coursegroups","where":{"and":[{"IsActive":${active}}, {"Since":{"between":["${year1}","${year2}"]}}, {"SchoolID":{"inq":[${schools}]}} ]} }`

    return this._httpService.httpGet(url);
  }

  getCoursesOfersByCourse(courses:number[],active:boolean): Observable<CoursesOfer[]>{
    let url: string = `CoursesOfers?filter={"where":{"and":[{"IsActive":${active}}, {"CourseID":{"inq":[${courses}]}} ]} }`
    return this._httpService.httpGet(url);
  }

  getAcademicaAreaBySchool(schools:number[]): Observable<AcademicArea[]>{
    let url: string = `AcademicaAreas?filter={"where": {"SchoolID": {"inq": [${schools}]}}}`
    return this._httpService.httpGet(url);
  }

  getSubjectsByCourse(courses:number[] ): Observable<Subjects[]>{
    let url: string = `Subjects?filter={"where":{"and":[ {"CourseID":{"inq":[${courses}]}} ]} }`
    return this._httpService.httpGet(url);
  }
  getSubjectGroups(subjects:number[],active:boolean): Observable<SubjectGroup[]>{
    let url: string = `SubjectGroups?filter={"include":{"relation":"userapp","scope":{"fields":{"CedocEmail":"true"}} },"where":{"and":[{"IsActive":${active}},{"SubjectID":{"inq":[${subjects}]}} ]} }`
    return this._httpService.httpGet(url);
  }

}
