export class Subjects {
    public id:number;
    public NameSubject:string;
    public CourseID:number;
    public SubjectID_Requirement: number;
    public HPM: number;
    public HFH:number;
    public HTI:number;
    public Creditos: number;
    public Description:string;
    public AcademicAreaID:number;
    public SemesterNumber: number;
    public MaxSlots: number;
}
