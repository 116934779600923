
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExcelService {

  private fileName: string;
  private workBook: XLSX.WorkBook;
  constructor() { }

  public setFileName(fileName: string) {
    this.fileName = fileName;
    this.workBook = XLSX.utils.book_new();
  }

  public createSheet(sheetName: string, data: any[]) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(this.workBook, ws, sheetName.slice(0,30).replace(/[^0-9a-zA-Z_\s ]/g, ''));
  }

  public writeFile(){
    XLSX.writeFile(this.workBook, this.fileName+".xlsx");
  }
}