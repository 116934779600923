<div *ngIf="isDisplay" class="question-main">
        <div class="question-name">
            {{field.NameField}}
        </div>

        <div class="question-options">
            <div class="options">
                <div *ngIf="field.TypeField == eTypeQuestion.opc1" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="5" >
                                Se cumple plenamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="4">
                                Se cumple en alto grado
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="3">
                                Se cumple satisfactoriamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="2">
                                Se cumple insatisfactoriamente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="1">
                                No se cumple
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc2" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="5" >
                                Totalmente de Acuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="4">
                                De acuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="3">
                                Indiferente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="2">
                                En Desacuerdo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="1">
                                Totalmente en Desacuerdo
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc3" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="1" checked>
                                1
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="2">
                                2
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="3">
                                3
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="4">
                                4
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="5">
                                5
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div *ngIf="field.TypeField == eTypeQuestion.opc4" class="">
                    <fieldset class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="5">
                                Si
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="1">
                                No
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="{{field.id}}" (change)="onValueChange($event.target.value)" value="0" checked>
                                No Aplica
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div  *ngIf="field.TypeField == eTypeQuestion.opc5" class="">
                    <div class="form-group">
                        <label for="exampleTextarea">Esriba su respuesta</label>
                        <textarea class="form-control" name="{{field.id}}" (change)="onValueChange($event.target.value)" rows="3"></textarea>
                    </div>
                </div>
            </div>

        </div>
</div>