import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { School } from 'src/app/models/school';
import { FormInstrument } from 'src/app/models/formInstrument';
import { FormModule } from 'src/app/models/formModule';
import { FormField } from 'src/app/models/formField';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { Category } from 'src/app/models/catergory';
import { TypeCourse } from 'src/app/models/typeCourse';
import { Modality } from 'src/app/models/modality';
import { Course } from 'src/app/models/course';
import { CoursesOfer } from 'src/app/models/coursesOfer';
import { CourseGroup } from 'src/app/models/courseGroup';
import { AcademicArea } from 'src/app/models/academicArea';
import { Subjects } from 'src/app/models/subjects';
import { SubjectGroup } from 'src/app/models/subjectGroup';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/models/roleMapping';
import { TypeRole } from 'src/app/models/indexForm';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BaseChartDirective } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'autoeval-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss']
})
export class UsersFilterComponent implements OnInit {

  @Input()
  schoolID: number
  @Input()
  isRolFilter: boolean
  @Input()
  title: string
  @Input()
  back: string
  @Output()
  userSchoolChoice = new EventEmitter<School>();
  @Output()
  userRoleChoice = new EventEmitter<String>();
  @Output()
  userList = new EventEmitter<{ UserID: number }[]>();
  @Output()
  CourseList = new EventEmitter<number[]>();
  
  public principalAppName = environment.principalAppName.toLowerCase();
  public showDataToCelic = false;
  startDate: Date = null;
  endDate: Date = null;
  public mySchool: number;
  public yearSearch: number = 2017;
  public targetPeople: number = -1;
  public myFormInstrument: FormInstrument;
  public typeRole = TypeRole.Estudiante
  barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };
  public yearList: number[] = [this.yearSearch];
  public doughnutChartData: [] = [];
  public barChartData: number[]= [350, 450, 100]
  public categories: Category[] = [new Category()];
  public typeCourses: TypeCourse[] = [new TypeCourse()];
  public modalities: Modality[] = [new Modality()];
  public courses: Course[] = [new Course()];
  public coursesOfers: CoursesOfer[] = [new CoursesOfer()];
  public courseGroups: CourseGroup[] = [new CourseGroup()];
  public academicAreas: AcademicArea[] = [new AcademicArea()];
  public subjects: Subjects[] = [new Subjects()];
  public subjectGroups: SubjectGroup[] = [new SubjectGroup()];
  public roles: Role[] = [new Role];
  public noticias =true

  // Pie
  public pieChartOptions: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    responsive:true,
    plugins: { },
  };
  public chartColors: any[] = [ { backgroundColor:["#cf8bb0", "#86c7f3"] }];
  public pieChartLabels = []
  public pieChartLabel = [ 'Noticias Privadas', 'Noticias Públicas' ];
  
  public pieChartDatasets = [{
    data: []
  }];

  public pieChartLegend = true;
  public pieChartPlugins = [];
  // 
  public pieChartLabelEventos = [ 'Notificaciones Eventos', 'Correos Masivos' ];
  public pieChartDatasetsEventos = [ {
    data: []
  } ];
  //
  public categoriesAll: Category[] = [new Category()];
  public typeCoursesAll: TypeCourse[] = [new TypeCourse()];
  public modalitiesAll: Modality[] = [new Modality()];
  public coursesAll: Course[] = [new Course()];
  public coursesOfersAll: CoursesOfer[] = [new CoursesOfer()];
  public courseGroupsAll: CourseGroup[] = [new CourseGroup()];
  public academicAreasAll: AcademicArea[] = [new AcademicArea()];
  public subjectsAll: Subjects[] = [new Subjects()];
  public subjectGroupsAll: SubjectGroup[] = [new SubjectGroup()];
  public rolesAll: Role[] = [new Role];

  public categoriesSelected: Category[] = [new Category()];
  public typeCoursesSelected: TypeCourse[] = [];
  public modalitiesSelected: Modality[] = [new Modality()];
  public coursesSelected: Course[] = [];
  public coursesOfersSelected: CoursesOfer[] = [];
  public courseGroupsSelected: CourseGroup[] = [];
  public academicAreasSelected: AcademicArea[] = [];
  public subjectsSelected: Subjects[] = [new Subjects()];
  public subjectGroupsSelected: SubjectGroup[] = [new SubjectGroup()];
  public rolesSelected: Role[] = [];
  public ListadoCorreos :any[];
  public isActiveOfer: boolean = true;
  public isActiveSubjecGroup: boolean = true;
  private functionCalls = 0;
  public showViewModal= false;
  public showChartModal= false;
  public vbusquedaCorreo : string='';
  public eTypeRole = TypeRole;
  public countPrivate = 0;
  public countNoprivate = 0;
  public schools: School[];
  public countCorreos = 0;
  public countEventos = 0;
  public schoolIget;
  single: any[] = []; 
  view: any[] = [700, 400];

  constructor(
    private router: Router,
    private courseService: CourseService,
    private userService: UserService,
    private schoolService: SchoolService,
  ) {
    this.schools = [new School()]
    this.myFormInstrument = new FormInstrument();
    this.myFormInstrument.FormModules = [new FormModule()];
    this.myFormInstrument.FormModules[0].FormFields = [new FormField];
    this.showDataToCelic = (this.principalAppName == 'celic')
  }
  modalNoticias(){
    this.noticias = true
    this.startDate = new Date(null)
    this.endDate = new Date(null)
    this.pieChartDatasets[0].data = [0,0];
  }
  modalCorreos(){
    this.noticias = false
    this.startDate = new Date(null)
    this.endDate = new Date(null)
    this.pieChartDatasetsEventos[0].data = [0,0];
  }
  public ngOnInit(): void {
    let diferenceYear = new Date().getFullYear() - this.yearSearch;

    for (let i = 1; i <= diferenceYear; i++) {
      this.yearList.push(this.yearSearch + i)
    }

    this.mySchool = this.schoolID;
    this.schoolIget = this.schoolID
    this.getFixedFilters();
    this.schoolGet()
  }

  schoolGet(){
    let filter = {include:{childrenSchools:"childrenSchools"}, where:{id:this.schoolID} , order:"NameTSchool ASC"}
    this.schoolService.getSchoolsbyFilters(JSON.stringify(filter)).subscribe(res=>{
      this.schools =  this.flattenArray(res);
      this.schoolIget = this.schoolID
    });
  }
  flattenArray(arr) {
    return arr.map(item => {
        if (item.childrenSchools && item.childrenSchools.length > 0) {
            return [item, ...this.flattenArray(item.childrenSchools)];
        } else {
            return item;
        }
    }).flat();
  }
  cambioColegio(){
    this.startDate = new Date(null)
    this.endDate = new Date(null)
    this.pieChartDatasets[0].data = [0,0];
  }
  public navigate() {
    this.router.navigate([this.back]);
  }

  public setSchool(school: School) {
    this.mySchool = school.id;
    this.userSchoolChoice.emit(school);
    this.getFilters();
  }
  viewModalContent(){
    this.showViewModal = !this.showViewModal
  }
  _showChartModal(){
    this.showChartModal = !this.showChartModal
  }
  _showEstadisticas(){
    this.showChartModal = !this.showChartModal
    if(this.showChartModal)
      this.showChartModalConect();this.showChartModalEventos();
    this.schoolGet()
  }
  showChartModalConect(){
    let countPrivateArticleFilter
    let countPublicArticleFilter
    this.pieChartDatasets[0].data = [];
    this.countNoprivate = null
    this.countPrivate = null


    if(this.startDate != null && this.endDate != null && this.startDate <= this.endDate){
    
      let startDate = this.formatStartFecha(new Date(this.startDate));
      let endDate = this.formatEndFecha(new Date(this.endDate));
      if(this.schoolIget==1){
        countPrivateArticleFilter = {and:[{IsPrivate:1},{CreateAt:{between:[startDate,endDate]}}]}
        countPublicArticleFilter = {and:[{IsPrivate:0},{CreateAt:{between:[startDate,endDate]}}]}
      }else{
        countPrivateArticleFilter = {and:[{SchoolID:this.schoolIget},{IsPrivate:1},{CreateAt:{between:[startDate,endDate]}}]}
        countPublicArticleFilter = {and:[{SchoolID:this.schoolIget},{IsPrivate:0},{CreateAt:{between:[startDate,endDate]}}]}
      }
      this.courseService.getArticleFilter(JSON.stringify(countPrivateArticleFilter)).subscribe(res =>{        
        this.countPrivate = res.count
        this.pieChartDatasets[0].data.push(res.count)
        this.courseService.getArticleFilter(JSON.stringify(countPublicArticleFilter)).subscribe(res =>{
          this.countNoprivate = res.count
          this.pieChartDatasets[0].data.push(res.count)
        })
      })
    }else{
      this.countPrivate = 0
      this.countNoprivate = 0
      this.pieChartDatasets[0].data.push(0)
      this.pieChartDatasets[0].data.push(0)
    }
  }
  
  showChartModalEventos(){
    let filterEvento
    let filterCorreos
    this.pieChartDatasetsEventos[0].data = [];
    this.countEventos = null
    this.countCorreos = null
    if(this.startDate !=null && this.endDate != null && this.startDate <= this.endDate){
      let startDate = this.formatStartFecha(new Date(this.startDate));
      let endDate = this.formatEndFecha(new Date(this.endDate));
      filterEvento = {and:[{typeNotification:"Event"},{timeToSend:{between:[startDate,endDate]}}]}
      filterCorreos = {and:[{typeNotification:"bulk"},{timeToSend:{between:[startDate,endDate]}}]}
      this.courseService.getNotificationsFilter(JSON.stringify(filterEvento)).subscribe(res =>{
        this.countEventos = res.count
        this.pieChartDatasetsEventos[0].data.push(res.count)
        this.courseService.getNotificationsFilter(JSON.stringify(filterCorreos)).subscribe(res =>{
          this.countCorreos = res.count
          this.pieChartDatasetsEventos[0].data.push(res.count)
          
        })
      })
     }else{
        this.countEventos = 0
        this.countCorreos = 0
        this.pieChartDatasetsEventos[0].data.push(0)
        this.pieChartDatasetsEventos[0].data.push(0)
     }
  }
  formatStartFecha(date: Date): string {
    const dateString= date.toISOString().split("T")
    return dateString[0]+"T00:00:00.000Z"
  }
  formatEndFecha(date: Date): string {
     const dateString= date.toISOString().split("T")
    return dateString[0]+"T23:59:59.000Z"
  }

  public setYearSearch() {
    this.getFilters();
  }

  public setRoleSearch() {
    this.userRoleChoice.emit(this.typeRole);
    switch (this.typeRole) {
      case TypeRole.Acudiente:
      case TypeRole.Estudiante:
        this.isActiveOfer = true;
        this.isActiveSubjecGroup = true;
        this.getFilters();
        this.targetPeople = -1;
        break;

      case TypeRole.Egresado:
        this.isActiveOfer = false;
        this.isActiveSubjecGroup = false;
        this.getFilters();
        this.targetPeople = -1;
        break;

      case TypeRole.Docente:
        this.isActiveOfer = true;
        this.isActiveSubjecGroup = true;
        this.getFilters();
        this.targetPeople = -1;
        break;

      case TypeRole.Individual:
        this.isActiveOfer = true;
        this.isActiveSubjecGroup = true;
        this.getFilters();
        this.targetPeople = -1;
        break;

      case TypeRole.Administrativos:
        
        this.onRoleChange();
        if(this.rolesSelected.length>0){
          this.targetPeople = -1;
        }else{
          this.targetPeople = 0
        }
        break;
    }
    this.limpiaSelccion();

  }
  limpiaSelccion(){
    this.typeCoursesSelected = []
    // this.modalitiesSelected = []
    this.coursesSelected = []
    this.coursesOfersSelected = []
    this.courseGroupsSelected = []
    // this.subjectsSelected = []
    this.academicAreasSelected = []
    // this.subjectGroupsSelected = []
    this.rolesSelected = []

  }
  public getFixedFilters() {

    forkJoin([
      this.courseService.getCategories(),
      this.courseService.getModalities(),
      this.userService.getrole()]).subscribe(Val => {

        [this.categories, this.modalities, this.roles] = Val;

        this.categoriesAll = this.categories;
        this.categoriesSelected = this.principalAppName !='cedoc'? this.categories:[];

        this.typeCourses = this.categories.map(r => r.TypeCourses).reduce((acc, val) => acc.concat(val), [])
            .sort((a, b) => a.NameTypeCourse.localeCompare(b.NameTypeCourse));
        this.typeCoursesAll = this.typeCourses;
        this.typeCoursesSelected =[] // this.typeCourses;

        this.modalitiesAll = this.modalities;
        this.modalitiesSelected = this.principalAppName !='cedoc'? this.modalities:[];
        
        this.roles = this.roles.filter(r=>{ return !(r.id == 2 || r.id ==3 || r.id ==10 || r.id ==18)});
        this.rolesAll = this.roles;
        this.rolesSelected = [] // this.roles;
        this.targetPeople = 0
        this.getFilters();

      });
  }

  public getFilters() {

    const courseFilter = {
      where: { "SchoolID": { inq: [this.mySchool] } },
      include: ["Subjects"]
    }

    forkJoin([
      this.courseService.getCourses(JSON.stringify(courseFilter)),
      this.courseService.getAcademicaAreaBySchool([this.mySchool]),
      this.courseService.getCoursesOfers([this.mySchool], this.isActiveOfer, this.yearSearch)]).subscribe(
        Val => {
          [this.courses, this.academicAreas, this.coursesOfers] = Val;
          this.academicAreas = this.academicAreas.sort((a, b) => a.NameArea.localeCompare(b.NameArea))
          this.courses = this.courses.sort((a, b) => a.NameCourse.localeCompare(b.NameCourse))
          this.coursesAll = this.courses;
          
          this.subjects = this.courses.map(r => r.Subjects).reduce((acc, val) => acc.concat(val), []).sort((a, b) => a.NameSubject.localeCompare(b.NameSubject));
          this.subjectsAll = this.subjects;
          this.subjectsSelected = this.principalAppName !='cedoc'? this.subjects:[];
          
          this.academicAreasAll = this.academicAreas;
          
          this.coursesOfersAll = this.coursesOfers;
          this.courseGroups = this.coursesOfers.map(r => r.coursegroups).reduce((acc, val) => acc.concat(val), []).sort((a, b) => a.name.localeCompare(b.name)) ;
          this.courseGroupsAll = this.courseGroups;
          
          if (this.typeRole == 'Egresado'){
            this.academicAreasSelected = this.principalAppName !='cedoc'? this.academicAreas:[];
            this.coursesOfersSelected = this.coursesOfers;
            this.coursesSelected = this.principalAppName !='cedoc'? this.courses:[];
            this.courseGroupsSelected = this.courseGroups
          }

          this.courseService.getSubjectGroups(this.subjectsAll.map(d => d.id), this.isActiveSubjecGroup).subscribe(
            data => {
              this.subjectGroups = data;
              this.subjectGroupsAll = this.subjectGroups;
              this.subjectGroupsSelected = this.principalAppName !='cedoc'? this.subjectGroups:[];

              this.filterUsers()
            }
          )

        }
      );

  }


  public onRoleChange() {
    this.targetPeople = -1;
    this.filterUsers()
  }

  public onCategoryChange() {
    this.targetPeople = -1;
    this.typeCourses = this.typeCoursesAll.filter(r => {
      return !(this.categoriesSelected.findIndex(e => e.id == r.CategoryID) < 0);
    });
    this.typeCoursesSelected =  [] //this.typeCourses;
    this.typeCoursesSelected = [] //this.typeCourses;
    this.onTypeCourseChange();
  }

  public onModalityChange() {
    this.getCoursesOfer()
  }

  public onTypeCourseChange() {
    this.courses = this.coursesAll.filter(r => {
      return !(this.typeCoursesSelected.findIndex(e => e.id == r.TypeCourseID) < 0);
    }).sort((a, b) => a.NameCourse.localeCompare(b.NameCourse));
    this.coursesSelected = [] //this.courses;
    this.coursesSelected = [] //this.courses;
    this.onCourseChange();
  }
  public onTypeCourseChangeDocente(){
    this.courses = this.coursesAll.filter(r => {
      return !(this.typeCoursesSelected.findIndex(e => e.id == r.TypeCourseID) < 0);
    }).sort((a, b) => a.NameCourse.localeCompare(b.NameCourse));
    this.coursesSelected = [] //  []// this.courses;
    this.academicAreasSelected = [] //  []//this.academicAreas
    
    this.onCourseChange();
  }

  public onAcademicAreaChange() {
    this.getSubjects()
  }

  public onCourseChange() {
    this.CourseList.emit(this.coursesSelected.map(d => d.id));
    this.getCoursesOfer()
    this.getSubjects()
  }

  public onCourseOferChange() {
    
      if (this.typeRole == TypeRole.Acudiente) {
        this.targetPeople = -1;
        this.filterStudentToGetAcudientesData()
        this.courseGroupsSelected =  this.courseGroups;
      }
    else {
      this.courseGroups = this.courseGroupsAll.filter(r => {
        return !(this.coursesOfersSelected.findIndex(e => e.id == r.CourseOferID) < 0);
      });
       this.courseGroupsSelected =  [] //this.courseGroups;
      this.onCourseGroupChange();
      this.courseGroupsSelected = [] // this.courseGroups;
    }
    
  }

  public onCourseGroupChange() {
    this.getSubjectGroups()
  }

  public onSubjectChange() {
    this.getSubjectGroups();
  }

  public onSubjectGroupChange() {
    this.targetPeople = -1
    this.filterUsers();
  }

  private getCoursesOfer() {
    this.coursesOfers = this.coursesOfersAll.filter(r => {
      if (!(this.modalitiesSelected.findIndex(e => e.id == r.ModalityID) < 0))
        if (!(this.coursesSelected.findIndex(e => e.id == r.CourseID) < 0))
          return true
      return false
    });
    this.coursesOfersSelected = [] // this.coursesOfers;
    this.onCourseOferChange()
  }

  private getSubjects() {
    this.subjects = this.subjectsAll.filter(r => {
      if (!(this.academicAreasSelected.findIndex(e => e.id == r.AcademicAreaID) < 0)){
        if (!(this.coursesSelected.findIndex(e => e.id == r.CourseID) < 0)){
          return true
        }
      }else{
        return false
      }
    });
    
    this.subjectsSelected = this.subjects;
    if (this.typeRole != 'Docente'){
        this.onSubjectChange();
    }else{
      if(this.academicAreasSelected.length>0){
        this.onSubjectChangeArea()
      }else{
        this.targetPeople = 0
      }
    }
  }
  private onSubjectChangeArea(){
    const idsSubject = this.subjectsSelected.map(r=>r.id)
    this.subjectGroups = this.subjectGroupsAll.filter(r=> idsSubject.includes(r.SubjectID));
    this.subjectGroupsSelected = this.subjectGroups;

    this.onSubjectGroupChange();
  }
  private getSubjectGroups() {
    this.subjectGroups = this.subjectGroupsAll.filter(r => {
      
      if (!(this.subjectsSelected.findIndex(e => e.id == r.SubjectID) < 0)){
            return true
          }
        if (!(this.courseGroupsSelected.findIndex(e => e.id == r.CourseGroupID) < 0))
              return true
        return false
        
        });
    let CourseGroupsSelectedIds = this.courseGroupsSelected.map(x=>x.id)
    this.subjectGroups = this.subjectGroupsAll.filter(r=> CourseGroupsSelectedIds.includes(r.CourseGroupID))
    this.subjectGroupsSelected = this.subjectGroups;

    this.onSubjectGroupChange();
  }

  private filterUsers() {
    this.functionCalls++

    // if(this.functionCalls<2)
    switch (this.typeRole) {
      case TypeRole.Estudiante:
      case TypeRole.Acudiente:
        this.filterStudentToGetAcudientesData();
        break;

      case TypeRole.Egresado:
        this.filterStudent();
        break;

      case TypeRole.Docente:
      case TypeRole.Individual:
        this.filterProfesor();
        break;

      case TypeRole.Administrativos:
        if(this.rolesSelected.length > 0){
        this.userService.getAdmins(
          {
            schools: [this.mySchool], roles: this.rolesSelected.map(d => d.id)
          }).subscribe(
            data => {

              //listado de Correos Modal
              this.ListadoCorreos = data.users

              this.userList.emit(data.users);
              this.targetPeople = data.users.length;
              this.checkCalls();
              
            }
          );
        }else{
          this.targetPeople = 0
        }
        break;
    }
  }

  private checkCalls(){
    if(this.functionCalls>1)
    {
      this.functionCalls = 0;
      this.targetPeople=-1
      this.filterUsers()
    }
    else{
      this.functionCalls = 0;
    }
  }

  private filterStudent() {
    if (this.subjectGroupsSelected.length > 0) {
      let filter = {
        schools: [this.mySchool], subjegroups: this.subjectGroupsSelected.map(d => d.id)
      };
      this.userService.getStudents(
        filter).subscribe(
          data => {
            this.userList.emit(data.users);

            //listado de Correos Modal
            this.ListadoCorreos = data.users
            
            this.targetPeople = data.users.length;
            this.checkCalls();
          }
        );
    }
    else {
      this.targetPeople = 0
      this.checkCalls();
    }
  }

  public removeDuplicates(array) {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.CedocEmail === item.CedocEmail 
            && t.CourseGroupID === item.CourseGroupID 
            && t.CourseID === item.CourseID && t.CourseOferID === item.CourseOferID && t.SchoolID  === item.SchoolID
            && t.SubjectGroupID === item.SubjectGroupID && t.SubjectID === item.SubjectID && 
            t.UserID === item.UserID 
        ))
    );
  }
  public removeDuplicatesUser(array) {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.UserID === item.UserID 
        ))
    );
  }


  private filterStudentToGetAcudientesData() {
    if (this.coursesOfersSelected.length > 0) {
      
      let filter = {
        CourseOferIDs: this.coursesOfersSelected.map(d => d.id)
      };
      
      this.userService.getStudentsDataFromAcudentsData(filter).subscribe(data => {
        let result = this.removeDuplicatesUser(data.users)
        
        let CourseGroupsSelectedIds = this.courseGroupsSelected.map(x=>x.id)
        let subjectsSelectedIds = this.subjectsSelected.map(r => r.id)
        // let dataFiltereded = data.users.filter(r=> CourseGroupsSelectedIds.includes(r.CourseGroupID) ).filter(k=> subjectsSelectedIds.includes(k.SubjectID))
        let dataFiltereded = result.filter(r=> CourseGroupsSelectedIds.includes(r.CourseGroupID) )

        if(this.typeRole == TypeRole.Estudiante){

          //listado de Correos Modal
          this.ListadoCorreos = dataFiltereded
          this.userList.emit(dataFiltereded);
          this.targetPeople = dataFiltereded.length;
          this.checkCalls();

        }else if(this.typeRole == TypeRole.Acudiente){
          this.getDataAcudientes(dataFiltereded);
        }else{
          this.ListadoCorreos = []
          this.userList.emit([]);
          this.targetPeople = 0;
          this.checkCalls();
        }
      });
    } else {
      this.targetPeople = 0
      this.checkCalls();
    }
  }


  private filterProfesor() {
    if (this.typeCoursesSelected.length > 0) {
      this.userService.getTeachers(
        {
          schools: [this.mySchool], subjegroups: this.subjectGroupsSelected.map(d => d.id)
        }).subscribe(
          data => {

            //listado de Correos Modal
            this.ListadoCorreos = data.users

            this.userList.emit(data.users);
            this.targetPeople = data.users.length;
            this.checkCalls();
          }
        );
    }
    else {
      this.targetPeople = 0
      this.checkCalls();
    }
  }

  getDataAcudientes(data: {CedocEmail: string, UserID: number}[]): void {
    let allStudentsIds = [];
    data.forEach(studentData => {
      allStudentsIds.push(studentData.UserID);
    });
    if (allStudentsIds.length == 0) {
      this.targetPeople = 0
      this.checkCalls();
      return;
    }
    
      this.userService.getUserAcudientes(allStudentsIds).subscribe(dataAcudientes => {
        let dataToSend = dataAcudientes.map(data => ({CedocEmail: data.parentUser.email, UserID: data.ParentUserId}));
        
        //listado de Correos Modal
        this.ListadoCorreos = dataToSend
        this.userList.emit(dataToSend);
        this.targetPeople = dataToSend.length;
        this.checkCalls();
      });
  }
}