import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { TemplateMainComponent } from './pages/template-main/template-main.component';
import { TemplateEditComponent } from './pages/template-edit/template-edit.component';
import { TemplateBoardComponent } from './pages/template-board/template-board.component';
import { TemplateIndexComponent } from './pages/template-index/template-index.component';
import { FolderElmComponent } from './pages/template-main/folder-elm/folder-elm.component';
import { FileElmComponent } from './pages/template-main/file-elm/file-elm.component';
import { QuestionElmComponent } from './pages/template-edit/question-elm/question-elm.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './components/shared.module';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { NewsComponent } from './pages/news/news.component';
import { TemplateUserComponent } from './pages/template-user/template-user.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    TemplateMainComponent,
    TemplateEditComponent,
    TemplateBoardComponent,
    TemplateIndexComponent,
    FolderElmComponent,
    FileElmComponent,
    QuestionElmComponent,
    BlogComponent,
    ArticleComponent,
    NewsComponent,
    TemplateUserComponent,
    
  ],
  imports: [

    BrowserModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    AngularEditorModule 

  ],
  exports:[QuestionElmComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
