
<section *ngIf="isAuth">
	

    <autoeval-users-filter [schoolID]="myArticle.SchoolID" [isRolFilter]="isRolefilter" [back]="'/wellness'"
        [title]="'Enviar ' +  namePost" (userSchoolChoice)="setSchool($event)" (userList)="setUsers($event)">
    </autoeval-users-filter>
    <!-- modal de Visualización -->
    <div class="transfer-modal__container" [ngStyle]="{'visibility': showViewModal ? 'visible' : 'hidden'}" >
			<div class="transfer-modal">
					<div class="transfer-modal__header-container">
						<div class="transfer-modal__header">
							<button (click)="viewModalContent()" class="button "><em class="icon-i-close close-icon"></em></button>
						</div>
					</div>
					<div>
						<div _ngcontent-avd-c10="" >
							<div class="row">								
								<div _ngcontent-avd-c10="" class="col-6 contentInfo">
									<div>
										<span _ngcontent-avd-c10="" target="_blank" class="news-data__info-tittle" >{{myArticle.Title}} </span>
										<div _ngcontent-avd-c10="" class="news-data__info-by" *ngIf="myArticle.CareAreaID"  >Creado por: {{getCategoria(myArticle.CareAreaID)}}</div>
										<a _ngcontent-avd-c10="" target="_blank" rel="noopener" href={{myArticle.linkArticle}}  *ngIf="myArticle.linkArticle">
											<div _ngcontent-avd-c10="" class="newsLink"><strong _ngcontent-avd-c10="">Ver enlace</strong>
											</div>
										</a>
										<div _ngcontent-vge-c10="" class="news-data__info-separator"></div>
										<div [innerHTML]="setHtmlContent(myArticle.Description)" >
										</div>
										<!-- <div style="text-align: center;"><u style="font-size: 1rem;"><font face="Calibri" color="#0d0d0d"><sub style="background-color: rgb(151, 17, 17);">fdfdfdfd</sub></font></u><br></div> -->
									</div>
								</div>
								<div _ngcontent-avd-c10="" class="col-6">
									<img _ngcontent-avd-c10="" alt="img Noticia" src={{this.myArticle.Imagen}} *ngIf="this.myArticle.Imagen" width="70%" >
								</div>
							</div>
						</div>
					</div>
			</div>
	</div>
    <div class="row">

        <div class="col-md-4">
            <div class="card">
                <div class="card-block">
                    <button [class.active]="stateType == eTypeArticle.email" (click)="setMail()" *ngIf="this.currentRoute=='email'" class="btn btn-outline-primary btn-lg btn-block"> Nuevo correo</button>
                    <button [class.active]="stateType == eTypeArticle.article" (click)="setArticle()" *ngIf="this.currentRoute!='email'" class="btn btn-outline-primary btn-lg btn-block"> Nueva noticia</button>
                    <button [class.active]="stateType == eTypeArticle.event" (click)="setEvent()" *ngIf="this.currentRoute !='email'" class="btn btn-outline-primary btn-lg btn-block"> Nuevo evento</button>
                    <!-- <button [class.active]="stateType == eTypeArticle.news" (click)="setNews()" 
                        class="btn btn-outline-primary btn-lg btn-block"> Nueva artículo</button> -->
                </div>
            </div>
        </div>

        
        <div class="col-md-8">
            <div class="card">
                <div class="card-block">

                    <span class="cat__core__title centertext d-block">
											<strong>
												Nuev{{ namePost == "Noticia" ? 'a' : 'o' }} {{namePost}}
											</strong>
											<br> <small class="text-muted mb-3">{{errorMessage}}</small>
                    </span>

                    <form #f="ngForm" ngNativeValidate (ngSubmit)="validateForm(f.value, f.valid)">
											<div class="row">
												<div class="col-md-12">
													<div class="form-group">
														<label class="form-label"><strong>Escriba aquí el título</strong></label>
														<input type="text" name="Title" class="form-control"
															[class.form-control-invaild]="!(Title.valid || (Title.pristine && !f.submitted))"
															placeholder="Título" [(ngModel)]="myArticle.Title" rows="5"
															minlength="2" maxlength="100" pattern="^[!-ž\s]{2,100}$" #Title="ngModel"
															required>
														<div class="form-control-error-list"
															[hidden]="Title.valid || (Title.pristine && !f.submitted)">
															<ul>
																<li>
																	Caracteres inválidos.
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="row" *ngIf="stateType == eTypeArticle.article">
												<div class="col-md-12">
													<div class="form-group">
														<label class="form-label"><strong>Ingresar el enlace (URL) que desea relacionar</strong></label>
														<input type="text" name="linkArticle" class="form-control"
															[class.form-control-invaild]="!(linkArticle.valid || (linkArticle.pristine && !f.submitted))"
															placeholder="URL" [(ngModel)]="myArticle.linkArticle" rows="5"
															minlength="2" maxlength="100" pattern="^[!-ž\s]{2,100}$" #linkArticle="ngModel">
														<div class="form-control-error-list"
															[hidden]="Title.valid || (Title.pristine && !f.submitted)">
															<ul>
																<li>
																	Mínimo 2 caracteres.
																</li>
																<li>
																	Máximo 100 caracteres.
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
                        <div class="row">
													<div class="col-md-6" *ngIf="stateType != eTypeArticle.event && stateType != eTypeArticle.email && categories?.length>0">
															<div class="form-group">
																<label class="form-label"><strong> Seleccione la dependencia</strong></label>
																<select class="form-control" #CareAreaID name="CareAreaID"
																	[(ngModel)]="myArticle.CareAreaID" required>
																	<option [ngValue]="undefined" disabled selected >
																		Seleccione la dependencia
																	</option>
																	<option *ngFor="let category of categories" [ngValue]="category.id"  >
																			{{category?.CareAreaName}}
																	</option>
																</select>
																<div class="form-control-error-list" [hidden]="(!f.submitted)">
																	<ul *ngIf="myArticle?.CareAreaID == null">
																		<li> Seleccione un departamento</li>
																	</ul>
																</div>
															</div>
													</div>
													<div class="col-md-6" *ngIf="stateType == eTypeArticle.event">

														<div class="form-group">

															<label class="form-label"><strong>Fecha Inicio</strong></label>
															<input type="datetime-local" name="fechaInicio" class="form-control"
																[class.form-control-invalid]="!fechaInicioModel.valid && fechaInicioModel.touched"
																[(ngModel)]="fechaInicio" required
																placeholder="AAAA-MM-DD HH:MM" #fechaInicioModel="ngModel" />
																	<div class="form-control-error-list"
																		[hidden]="fechaInicioModel.valid || (fechaInicioModel.pristine && !f.submitted)">
																		<ul>
																			<li> Digite una fecha valida.</li>
																		</ul>
																	</div>
														</div>
													</div>
													<div class="col-md-6" *ngIf="stateType == eTypeArticle.event">

														<div *ngIf="stateType == eTypeArticle.event" class="form-group">

															<label class="form-label"><strong>Fecha Final</strong></label>
															<input type="datetime-local" name="Until" class="form-control" required
																[class.form-control-invaild]='!(Until.valid || (Until.pristine && !f.submitted))'
																placeholder="Fin Del Semestre" [(ngModel)]="myArticle.Until"
																class="form-control" placeholder="AAAA-MM-DD HH:MM" #Until="ngModel" />
																<div class="form-control-error-list"
																	[hidden]="Until.valid || (Until.pristine && !f.submitted)">
																	<ul>
																		<li> Digite una fecha valida.</li>
																	</ul>
																</div>
														</div>
													</div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div *ngIf="stateType == eTypeArticle.news" class="form-group">
                                    <label class="form-label">
																			<strong>Escriba aquí el resumen de la publicación 400 caracteres </strong>
                                    </label>
                                    <angular-editor type="text" name="Summary" [config]="editorConfig" required
																			[class.form-control-invaild]="!(Summary?.valid || (Summary?.pristine && !f?.submitted))"
																			[(ngModel)]="myArticle.Summary" rows="5" minlength="2" maxlength="400"
																			pattern="{2,500}$" #Summary="ngModel"></angular-editor>
                                    <div class="form-control-error-list"
																			[hidden]="Summary.valid || (Summary.pristine && !f.submitted)">
																			<ul>
																				<li>
																					Mínimo 2 caracteres.
																				</li>
																				<li>
																					Máximo 500 caracteres.
																				</li>
																			</ul>
                                    </div>
                                </div>
                                <div *ngIf="stateType != eTypeArticle.news"
																	class="form-group">
																	<label class="form-label">
																			<strong>Escriba aquí el contenido (Máx. 2000 caracteres)</strong>
																	</label>
																	<!-- <textarea type="text" name="Description" class="form-control" required
																		[class.form-control-invaild]="!(Description.valid || (Description.pristine && !f.submitted))"
																		[(ngModel)]="myArticle.Description" rows="10" minlength="2" maxlength="2000"
																		pattern="{2,2000}$" #Description="ngModel"></textarea> -->
																		<angular-editor  [config]="editorConfig" name="Description"  required
																			[(ngModel)]="myArticle.Description" rows="10" #Description="ngModel" (ngModelChange)="onContentChange($event)" ></angular-editor>
																	<div class="form-control-error-list" >
																		<ul>
																			<li *ngIf="this.charCount<2">
																				Mínimo 2 caracteres.
																			</li>
																			<li *ngIf="this.charCount>2000">
																				Máximo 2000 caracteres.
																			</li>
																		</ul>
																	</div>
																	<span>Cantidad de caracteres: {{this.charCount}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="stateType == eTypeArticle.article && principalAppName === 'cedoc' && this.myArticle.SchoolID == 25 " class="my-3" >
													<label class="form-label">
														<input id="checkboxIsPrivate" type="checkbox" [(ngModel)]="myArticle.IsPrivate" #IsPrivate="ngModel" name="IsPrivate"/>
														<strong> ¿Noticia Privada?</strong>
													</label>
                        </div>
                        <label class="btn btn-file mr-3 mb-0" [ngClass]="{'activeFile': !fileUp}" [class]="btnclass" *ngIf="stateType == eTypeArticle.article">
													<i class="fa fa-file-image-o"></i> Cargar imagen
													<input type="file" name="DocumentFile" accept="image/*" #inputFile (change)="uploadFileDocumentsIm($event)" style="display: none;">
                        </label>
                        <label class="btn btn-file mr-3 mb-0" [ngClass]="{'activeFile': !fileUp}" [class]="btnclass" *ngIf="stateType == eTypeArticle.email || stateType == eTypeArticle.event">
													<i class="fa fa-file-image-o"></i> Cargar documento
													<input type="file" name="DocumentFile" (change)="uploadFileDocuments($event)" style="display: none;">
                        </label>
                        <button type="button" class="btn btn-primary mr-3" name="Visualizar publicacion"  
													*ngIf="stateType == eTypeArticle.article && myArticle.Title && myArticle.Description" (click)="viewModalContent()">
															Visualizar publicación
                        </button>
                        <label class="btn btn-file mr-3 mb-0" [class]="btnclass" *ngIf="stateType == eTypeArticle.event && this.ICS_FILE_URL != ''">
													<a [href]="ICS_FILE_URL" download="evento.ics">
														<i class="fa fa-file-image-o"></i> Descargar ICS
													</a>
                        </label>

                        <button type="submit" class="btn btn-primary">{{submitbutonName}}</button>
                        
                        <div class="mt-3" *ngIf="this.myArticle.Imagen" >
                            <span   >{{this.DOC_FILE_NAME}}</span> <button (click)="eliminaFoto()" class="button "><em class="icon-i-close close-icon"></em></button><br>
                            <img _ngcontent-avd-c10="" *ngIf="this.validaImage || (stateType == eTypeArticle.article)" alt="img Noticia" src={{this.myArticle.Imagen}} width="20%" >
                        </div>
                        <div class="mt-3" *ngIf="stateType == eTypeArticle.article">
														<span>
                                El archivo que no cumpla con estas restricciones no podrá ser cargado. <br>
                                * Por favor, asegurarse de que la imagen que desea cargar, cumple con las siguientes restricciones de tamaño y formato: <br>
                                Tamaño máximo de archivo: 5 megabytes (5MB) <br>
                                Formatos de archivo permitidos: .jpg,.jpeg,.png,.gif,.ico. <br>
                            </span>
                        </div>
                        <div class="mt-3" *ngIf="stateType == eTypeArticle.email">
                            <span>
                                * Por favor, asegurarse de que el archivo que desea cargar, cumple con las siguientes restricciones de tamaño y formato: <br>
                                Tamaño máximo de archivo: 5 megabytes (5MB) <br>
                                Formatos de archivo permitidos: .jpg,.jpeg,.png,.gif,.ico,.pdf,.xlsx,.docx. <br>
                            </span>
                        </div>
                        <div class="mt-3" *ngIf="stateType == eTypeArticle.event">
                            <span>
                                * Por favor, asegurarse de que el archivo que desea cargar, cumple con las siguientes restricciones de tamaño y formato: <br>
                                Tamaño máximo de archivo: 5 megabytes (5MB) <br>
                                Formatos de archivo permitidos: .jpg,.jpeg,.png,.gif,.ico,.pdf,.xlsx,.docx.<br>
                                El archivo que no cumpla con estas restricciones no podrá ser cargado.<br>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>