import { CourseGroup } from './courseGroup';

export class CoursesOfer {
    public id:number;
    public NameCourseOfer:string;
    public CourseID:number;
    public IsActive: boolean;
    public Since: Date;
    public Until: Date;
    public RealSince:Date;
    public RealUntil: Date;

    public SchoolID:number;
    public Price:number;
    public TypeCourseID:number;
    public IsSemestralize: boolean;
    public DepartamentID: number;
    public TypeTrainingID:number;
    public Altitud:string;
    public Longitud: string;

    public InscriptStart:number;
    public InscriptEnd:string;
    public SemesterNumber:string;
    public ModalityID: number;
    public TotalPeople: string;
    public OferState:string;
    public Profile:string;

    public coursegroups:CourseGroup[];
    
}
