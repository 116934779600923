import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { FormFolder } from '../models/formFolder';
import { FormInstrument } from '../models/formInstrument';
import { FormModule } from '../models/formModule';
import { FormField } from '../models/formField';
import { IndexForm } from '../models/indexForm';
import { IndexFormUser } from '../models/indexFormUser';
import { UserAnswer } from '../models/userAnswer';



@Injectable({
  providedIn: 'root'
})
export class FormfolderService {

  constructor(
    private _httpService: HttpService
  ) { }


  getformInstrumentsInFolder(FormFolderID:number): Observable<FormFolder>{
    let url: string = `FormFolders/${FormFolderID}?filter={"include":["FormInstruments","childresnFolders"]}`
    return this._httpService.httpGet(url);
  }

  newFormFolder(data:any): Observable<FormFolder>{
    let url: string = "FormFolders"
    return this._httpService.httpPost(url,data)
  }

  newFormInstrument(data:any): Observable<FormInstrument>{
    let url: string = "FormInstruments"
    return this._httpService.httpPost(url,data)
  }

  editFormInstrument(data:any): Observable<FormInstrument>{
    let url: string = "FormInstruments"
    return this._httpService.httpPatch(url,data)
  }

  deleteFormInstrument(id:number): Observable<FormInstrument>{
    let url: string = `FormInstruments/${id}`
    return this._httpService.httpDelete(url)
  }

  newFormModule(data:any): Observable<FormModule>{
    let url: string = "FormModules"
    return this._httpService.httpPost(url,data)
  }

  editFormModule(data:any): Observable<FormModule>{
    let url: string = "FormModules"
    return this._httpService.httpPatch(url,data)
  }

  newFormField(data:any): Observable<FormField>{
    let url: string = "FormFields"
    return this._httpService.httpPost(url,data)
  }

  editFormField(data:any): Observable<FormField>{
    let url: string = "FormFields"
    return this._httpService.httpPatch(url,data)
  }

  countFormFieldAnswers(formFieldID:any): Observable<any>{
    let url: string = `FormFields/${formFieldID}/UserAnswers/count`
    return this._httpService.httpGet(url)
  }

  deleteFormField(formFieldID:any): Observable<FormField>{
    let url: string = `FormFields/${formFieldID}`
    return this._httpService.httpDelete(url)
  }

  getFormInstrumentById(FormID:number): Observable<FormInstrument>{
    let url: string = `FormInstruments/${FormID}?filter={"include":[{"FormModules":["FormFields"]}]}`
    return this._httpService.httpGet(url)
  }

  getFormInstrumentResultsById(FormID:number,IndexFormIDs:number[]): Observable<FormInstrument>{


    let url = {"include":
    [
      {"FormModules":["FormFields"]},
      {"relation":"IndexForms","scope":{
        "include":[
          {"relation":"IndexFormUsers","scope":{
            "include":["UserAnswers"]
          }}
        ],
        "where":{"id":{"inq": IndexFormIDs}}}}
    ]}

    return this._httpService.httpGet(`FormInstruments/${FormID}?filter=`+JSON.stringify(url))
  }

  newIndexForm(data:any): Observable<IndexForm>{
    let url: string = "IndexForms"
    return this._httpService.httpPost(url,data)
  }

  newIndexFormUsers(data:any): Observable<[IndexFormUser]>{
    let url: string = "IndexFormUsers"
    return this._httpService.httpPost(url,data)
  }

  UpdateIndexFormUsers(data:any): Observable<[IndexFormUser]>{
    let url: string = "IndexFormUsers"
    return this._httpService.httpPatch(url,data)
  }

  newUserAnswers(data:any): Observable<[UserAnswer]>{
    let url: string = "UserAnswers"
    return this._httpService.httpPost(url,data)
  }

  getIndexFormByFormID(FormID:number): Observable<[IndexForm]>{
    let url: string = `FormInstruments/${FormID}/IndexForms?filter={"include":"IndexFormUsers","order":"Percentage DESC"}`
    return this._httpService.httpGet(url)
  }

  getIndexFormUserId(id:number): Observable<IndexFormUser>{
    let url: string = `IndexFormUsers/${id}?filter={"include":{"IndexForm":{"FormInstrument":{"FormModules":"FormFields"}}}}`
    return this._httpService.httpGet(url);
  }

}
