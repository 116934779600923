import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormInstrument } from 'src/app/models/formInstrument';
import { IndexForm, TypeRole } from 'src/app/models/indexForm';
import { MultiBar, SeriesData } from 'src/app/models/multiBar';
import { UserAnswer } from 'src/app/models/userAnswer';
import { ExcelService } from 'src/app/services/excel.service';
import { FormfolderService } from 'src/app/services/formfolder.service';

@Component({
  selector: 'autoeval-template-board',
  templateUrl: './template-board.component.html',
  styleUrls: ['./template-board.component.css']
})
export class TemplateBoardComponent implements OnInit {

  public multi: MultiBar[];


  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Población';
  animations: boolean = true;

  colorScheme = {
    domain: ['#090C08', '#474056', '#757083', '#8A95A5', '#B9C6AE']
  };


  public isData = false;
  public isAuth = false;
  public mySchool: number;

  public eTypeRole = TypeRole;
  
  public myFormInstrument: FormInstrument;
  public userAnswers: UserAnswer[] = []


  constructor(
    private route: ActivatedRoute,
    private formfolderService: FormfolderService,
    private xlsxService: ExcelService,
  ) { 

  }

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {

    
    this.route.queryParams.subscribe(params => {

      if ( !!params['instrument'] && !!params['Forms'] ) {

        this.formfolderService.getFormInstrumentResultsById(params['instrument'],params['Forms']).subscribe(
          data => {
            this.myFormInstrument = data;
            this.xlsxService.setFileName(this.myFormInstrument.NameForm);

            this.filterAnswered()

            // Map de grafica por modulo
            this.myFormInstrument.FormModules = this.myFormInstrument.FormModules.map(module=>{
              const graphMod:SeriesData[] = []

              // Map de grafica por pregunta
              module.FormFields = module.FormFields.map(field => {
                
                field.UserAnswers = this.userAnswers.filter(e => { return e.FormFieldID == field.id})
                

                
                let fieldgraph = this.initMulti();

                // TODO: calc percentage field
                field.Percentage=field.UserAnswers.reduce((a,e)=>{ 
                  return a + (!isNaN(Number(e.value))?Number(e.value):0) 
                },0);
                field.Percentage = field.Percentage/field.UserAnswers.length*5
                

                fieldgraph.forEach((roleGrph,i) => {
                  const roleanswer:UserAnswer[] =  field.UserAnswers.filter(answer => { return answer.TypeRole == roleGrph.name });

                  roleGrph.series.forEach((valueGraph,j) => {
                    let temp = roleanswer.filter(answer => {
                      return answer.value == valueGraph.name;
                    })

                    fieldgraph[i].series[j].value = temp.length;
                    
                  });
                });
                
                field.graph = fieldgraph;
                graphMod.push({name:field.NameField,value:field.Percentage});

                const dataField = field.UserAnswers.map(a => {
                  return {"fecha":a.CreatedAt,"rol":a.TypeRole,"respuesta":a.value}
                });
                this.xlsxService.createSheet(field.NameField,dataField);
                return field;
              });
              
              module.graph = graphMod;

              this.xlsxService.createSheet("Modulo:"+module.NameModule,module.graph);
              return module
            })

            this.isData = true;
          }
        );

      }
    });
  }

  private filterAnswered(){

    this.myFormInstrument.IndexForms.forEach(idxfm=>{
      idxfm.IndexFormUsers.forEach(fu => {
        if(fu.IsAnswer){
          this.userAnswers.push(...fu.UserAnswers.map(e=>{
            e.TypeRole = idxfm.TypeRole
            return e;
          }))
        }
      })
    });
  }

  private initMulti(){
    return [
      {
        "name": "Total",
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 1
          },
          {
            "name": "3",
            "value": 1
          },
          {
            "name": "4",
            "value": 1
          },
          {
            "name": "5",
            "value": 1
          }
        ]
      },
      {
        "name": TypeRole.Administrativos,
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 0
          },
          {
            "name": "3",
            "value": 0
          },
          {
            "name": "4",
            "value": 0
          },
          {
            "name": "5",
            "value": 0
          }
        ]
      },
      {
        "name": TypeRole.Docente,
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 0
          },
          {
            "name": "3",
            "value": 0
          },
          {
            "name": "4",
            "value": 0
          },
          {
            "name": "5",
            "value": 0
          }
        ]
      },
    
      {
        "name": TypeRole.Egresado,
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 0
          },
          {
            "name": "3",
            "value": 0
          },
          {
            "name": "4",
            "value": 0
          },
          {
            "name": "5",
            "value": 0
          }
        ]
      },
    
      {
        "name": TypeRole.Estudiante,
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 0
          },
          {
            "name": "3",
            "value": 0
          },
          {
            "name": "4",
            "value": 0
          },
          {
            "name": "5",
            "value": 0
          }
        ]
      },
    
      {
        "name": TypeRole.Individual,
        "series": [
          {
            "name": "1",
            "value": 0
          },
          {
            "name": "2",
            "value": 0
          },
          {
            "name": "3",
            "value": 0
          },
          {
            "name": "4",
            "value": 0
          },
          {
            "name": "5",
            "value": 1
          }
        ]
      }
    ];
  }

  public exportData() {
    this.xlsxService.writeFile();
  }
}
