
    <div class="card">
      <div class="card-header">
        <div class="row">
					<div class="col-4">
            <div class="float-left ">
                <button  (click)="navigate()" class="btn btn-sm btn-outline-primary ladda-button divNoticias">Lista de noticias</button>
            </div>

            <div class="float-left">
                <button  (click)="_showEstadisticas()" class="btn btn-sm btn-outline-primary ladda-button">Estadísticas</button>
            </div>
					</div>            
					<div class="col-4">
						<span class="cat__core__title centertext d-block">
							<span class="d-block" *ngIf="principalAppName == 'cedoc'">
								<strong>
										{{title}}
								</strong>
								<br>
								Selecciona escuela
							</span>
							<span class="d-block" *ngIf="principalAppName != 'cedoc'">
								<strong>
										{{title}}
								</strong>
								<br>
								Seleccione el Liceo
							</span>
						</span>
					</div>
					<div >
						<!-- <div class="float-right">
                <button  (click)="navigate()" class="myHiddenBtn">Volver</button>
            </div> -->
					</div>
        </div>
      </div>
      <div class="filterbar">
        <autoeval-curtain-menu [schoolID]="mySchool" (userSchoolChoice)="setSchool($event)"  ></autoeval-curtain-menu>
      </div>
    </div>
    
    <div *ngIf="isRolFilter" class="card">
        <div class="card-header ">
            <span class="cat__core__title form-inline centertext d-block">
							<div class="title-role">
									<span>
											Usuarios con rol
									</span>

									<select class="form-control" (change)="setRoleSearch()" [(ngModel)]="typeRole" >
											<option [ngValue]="eTypeRole.Individual" *ngIf="principalAppName == 'cedoc'">Individual</option>
											<option [ngValue]="eTypeRole.Estudiante">Estudiante</option>
											<option [ngValue]="eTypeRole.Egresado" *ngIf="principalAppName == 'cedoc'">Egresado</option>
											<option [ngValue]="eTypeRole.Docente">Docente</option>
											<option [ngValue]="eTypeRole.Administrativos">Administrativo</option>
											<ng-container *ngIf="showDataToCelic">
													<option [ngValue]="eTypeRole.Acudiente">Acudiente</option>
											</ng-container>
									</select>
									<ng-template [ngIf]="typeRole == eTypeRole.Egresado">
											<span>
													del año:
											</span>
											<select class="form-control" (change)="setYearSearch()" [(ngModel)]="yearSearch" >
													<option *ngFor="let year of yearList" [ngValue]="year">{{year}}</option>
											</select>
									</ng-template >
									<span>
										seleccionadas:
									</span>
									<span *ngIf="targetPeople==-1" class="loading">
										<i class="fa fa-refresh"></i>
									</span>
									<strong  *ngIf="targetPeople!=-1">
										{{targetPeople}}
									</strong>
									<em *ngIf="targetPeople>0" class="zaia-menu correosModal" (click)="viewModalContent()">Ver Correos</em>
							</div>
            </span>        
        </div>
        
        <div class="transfer-modal__container" [ngStyle]="{'visibility': showViewModal ? 'visible' : 'hidden'}" >
            <div class="transfer-modal">
                <div class="transfer-modal__header-container">
									<div class="transfer-modal__header">
										<h2 class="transfer-modal__title">																					
										</h2>
										<button (click)="viewModalContent()" class="button "><em class="icon-i-close close-icon"></em></button>
									</div>
                </div>
								<div>
									<div _ngcontent-avd-c10="" >
										<div class="row">
											<div _ngcontent-avd-c10="" class="col-12 contentInfo">
												<div>
													<div class="textListadoCorreo">
														<span _ngcontent-avd-c10="" target="_blank" class="news-data__info-tittle" >Lista de Correos</span>
													</div>
													<div _ngcontent-vge-c10="" class="news-data__info-separator"></div>
													<div _ngcontent-avd-c10=""  >
														<label class="form-label"><strong>Filtrar por correo</strong></label>
															<input type="text" name="Title" class="form-control" placeholder="Correo" rows="5"
																minlength="2" maxlength="100" pattern="^[!-ž\s]{2,100}$" 
																required  [(ngModel)]="vbusquedaCorreo" />
														<table tyle="width: 100%; ">
															<thead style="width: 100%;display: block">
																<tr>
																	<ng-container >
																		<th>#</th>
																		<th>Correos</th>
																	</ng-container>
																</tr>
															</thead>
															<tbody style="height: 270px; overflow: auto;display: block">
																<tr *ngFor="let item of ListadoCorreos; let i = index">
																	<ng-container *ngIf="vbusquedaCorreo === '' || item.CedocEmail.includes(vbusquedaCorreo)">
																		<td>{{ i + 1 }}</td>
																		<td>{{ item.CedocEmail }}</td>
																	</ng-container>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>

											<div _ngcontent-avd-c10="" class="col-6">
											</div>
										</div>
									</div>
								</div>
            </div>
        </div>


        <ng-container [ngSwitch]="typeRole">
            <ng-template [ngSwitchCase]="eTypeRole.Estudiante">
                <div class="card-header ">
                    <div class="flex-3-cols">
                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc " >
                                    <span class="cat__core__step__title">Tipo de Programa</span>

                                    <ng-select [items]="categories"
																			bindLabel="NameCategoryCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCategoryChange()"
																			[(ngModel)]="categoriesSelected">

																			<ng-template ng-header-tmp>
																					<button (click)="categoriesSelected = categories;onCategoryChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--info">
                                <div class="cat__core__step__desc">
																	<span class="cat__core__step__title">Categoría</span>
																	<ng-select [items]="typeCourses" *ngIf="typeCourses"
																		bindLabel="NameTypeCourse"
																		placeholder="Seleccionar Categoría"
																		[closeOnSelect]="false"
																		multiple="true"
																		(change)="onTypeCourseChange()"
																		[(ngModel)]="typeCoursesSelected"  >
																		<ng-template ng-header-tmp>
																			<button (click)="typeCoursesSelected = typeCourses; onTypeCourseChange()" 
																			class="btn btn-sm btn-block btn-primary">Todos</button>
																		</ng-template>
																		<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																				<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameTypeCourse}}
																		</ng-template>
																	</ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Modalidad</span>
                                    <ng-select [items]="modalities"
                                                bindLabel="nameModality"
                                                placeholder="Seleccionar modalidad"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onModalityChange()"
                                                [(ngModel)]="modalitiesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="modalitiesSelected = modalities; onModalityChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.nameModality}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--primary">
															<div class="cat__core__step__desc">
																<span class="cat__core__step__title">Nivel de escolaridad</span>
																<ng-select  class="mySelect" [items]="courses"
																	bindLabel="NameCourse"
																	placeholder="Seleccionar Nivel de escolaridad"
																	[closeOnSelect]="false"
																	multiple="true"
																	(change)="onCourseChange()"
																	[(ngModel)]="coursesSelected" >
																	<ng-template ng-header-tmp>
																			<button (click)="coursesSelected = courses; onCourseChange()" 
																			class="btn btn-sm btn-block btn-primary">Todos</button>
																	</ng-template>
																	<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																			<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourse}}
																	</ng-template>
																</ng-select>
															</div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de oferta</span>
                                    <ng-select [items]="coursesOfers"
                                                bindLabel="NameCourseOfer"
                                                placeholder="Seleccionar Nombre de oferta"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseOferChange()"
                                                [(ngModel)]="coursesOfersSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="coursesOfersSelected = coursesOfers;onCourseOferChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourseOfer}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--info">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de oferta</span>
                                    <ng-select [items]="courseGroups"
                                                bindLabel="name"
                                                placeholder="Seleccionar Grupo de oferta"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseGroupChange()"
                                                [(ngModel)]="courseGroupsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="courseGroupsSelected = courseGroups; onCourseGroupChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Área académica</span>
                                    <ng-select [items]="academicAreas"
                                                bindLabel="NameArea"
                                                placeholder="Seleccionar filtro"
                                                appendTo="body"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onAcademicAreaChange()"
                                                [(ngModel)]="academicAreasSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="academicAreasSelected = academicAreas" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameArea}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--secundary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de Materia</span>
                                    <ng-select [items]="subjects"
																			bindLabel="NameSubject"
																			placeholder="Seleccionar nombre de materia"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onSubjectChange()"
																			[(ngModel)]="subjectsSelected">
																			<ng-template ng-header-tmp>
																							<button (click)="subjectsSelected = subjects; onSubjectChange()" 
																							class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																							<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameSubject}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de Materia</span>
                                    <ng-select [items]="subjectGroups"
																			bindLabel="userapp.CedocEmail"
																			placeholder="Seleccionar grupo de materia"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onSubjectGroupChange()"
																			[(ngModel)]="subjectGroupsSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="subjectGroupsSelected = subjectGroups; onSubjectGroupChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.userapp.CedocEmail}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template >

            <ng-template [ngSwitchCase]="eTypeRole.Acudiente">
                <div class="card-header ">
                    <div class="flex-3-cols">
                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>

                                    <ng-select [items]="categories"
                                                bindLabel="NameCategoryCourse"
                                                placeholder="Seleccionar Categoría"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCategoryChange()"
                                                [(ngModel)]="categoriesSelected">

                                                <ng-template ng-header-tmp>
                                                    <button (click)="categoriesSelected = categories" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
                                                </ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--secondary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>
                                    <ng-select class="mySelect" [items]="typeCourses"
																			bindLabel="NameTypeCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onTypeCourseChange()"
																			[(ngModel)]="typeCoursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="typeCoursesSelected = typeCourses; onTypeCourseChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameTypeCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>


                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Modalidad</span>
                                    <ng-select [items]="modalities"
                                                bindLabel="nameModality"
                                                placeholder="Seleccionar modalidad"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onModalityChange()"
                                                [(ngModel)]="modalitiesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="modalitiesSelected = modalities; onModalityChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.nameModality}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--primary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nivel de escolaridad</span>
                                    <ng-select  class="mySelect" [items]="courses"
                                                bindLabel="NameCourse"
                                                placeholder="Seleccionar Nivel de escolaridad"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseChange()"
                                                [(ngModel)]="coursesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="coursesSelected = courses; onCourseChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourse}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de oferta</span>
                                    <ng-select [items]="coursesOfers"
                                                bindLabel="NameCourseOfer"
                                                placeholder="Seleccionar Nombre de oferta"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseOferChange()"
                                                [(ngModel)]="coursesOfersSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="coursesOfersSelected = coursesOfers; onCourseOferChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourseOfer}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4">
                            <div class="form-group cat__core__step cat__core__step--info">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de oferta</span>
                                    <ng-select [items]="courseGroups"
                                                bindLabel="name"
                                                placeholder="Seleccionar filtro"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseGroupChange()"
                                                [(ngModel)]="courseGroupsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="courseGroupsSelected = courseGroups" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
                <div class="card-header ">
                    <div class="row">
                        <!-- <div class="col-lg-4">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Área académica</span>
                                    <ng-select [items]="academicAreas"
                                                bindLabel="NameArea"
                                                placeholder="Seleccionar filtro"
                                                appendTo="body"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onAcademicAreaChange()"
                                                [(ngModel)]="academicAreasSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="academicAreasSelected = academicAreas" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameArea}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-lg-4">
                            <div class="form-group cat__core__step cat__core__step--secundary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de Materia</span>
                                    <ng-select [items]="subjects"
                                                bindLabel="NameSubject"
                                                placeholder="Seleccionar filtro"
                                                appendTo="body"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onSubjectChange()"
                                                [(ngModel)]="subjectsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="subjectsSelected = subjects" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameSubject}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-lg-4">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de Materia</span>
                                    <ng-select [items]="subjectGroups"
                                                bindLabel="userapp.CedocEmail"
                                                placeholder="Seleccionar filtro"
                                                appendTo="body"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onSubjectGroupChange()"
                                                [(ngModel)]="subjectGroupsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="subjectGroupsSelected = subjectGroups" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.userapp.CedocEmail}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
            </ng-template >

            <ng-template [ngSwitchCase]="eTypeRole.Egresado" *ngIf="principalAppName == 'cedoc'">
                <div class="card-header " >
                    <div class="flex-3-cols">

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>

                                    <ng-select [items]="categories"
                                                bindLabel="NameCategoryCourse"
                                                placeholder="Seleccionar Categoría"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCategoryChange()"
                                                [(ngModel)]="categoriesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="categoriesSelected = categories" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
                                                </ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--info">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>
                                    <ng-select  [items]="typeCourses"
																			bindLabel="NameTypeCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onTypeCourseChange()"
																			[(ngModel)]="typeCoursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="typeCoursesSelected = typeCourses; onTypeCourseChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameTypeCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--secondary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Modalidad</span>
                                    <ng-select [items]="modalities"
                                                bindLabel="nameModality"
                                                placeholder="Seleccionar modalidad"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onModalityChange()"
                                                [(ngModel)]="modalitiesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="modalitiesSelected = modalities;onModalityChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.nameModality}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nivel de escolaridad</span>
                                    <ng-select  class="mySelect" [items]="courses"
																			bindLabel="NameCourse"
																			placeholder="Seleccionar Nivel de escolaridad"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCourseChange()"
																			[(ngModel)]="coursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="coursesSelected = courses; onCourseChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourse}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de oferta</span>
                                    <ng-select [items]="coursesOfers"
                                                bindLabel="NameCourseOfer"
                                                placeholder="Seleccionar Nombre de oferta"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCourseOferChange()"
                                                [(ngModel)]="coursesOfersSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="coursesOfersSelected = coursesOfers;onCourseOferChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourseOfer}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de oferta</span>
                                    <ng-select [items]="courseGroups"
																			bindLabel="name"
																			placeholder="Seleccionar Grupo de oferta"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCourseGroupChange()"
																			[(ngModel)]="courseGroupsSelected">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--info">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Área académica</span>
                                    <ng-select [items]="academicAreas"
																			bindLabel="NameArea"
																			placeholder="Seleccionar Área académica"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onAcademicAreaChange()"
																			[(ngModel)]="academicAreasSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="academicAreasSelected = academicAreas; onAcademicAreaChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameArea}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--secundary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de Materia</span>
                                    <ng-select [items]="subjects"
                                        bindLabel="NameSubject"
                                        placeholder="Seleccionar nombre de materia"
                                        [closeOnSelect]="false"
                                        multiple="true"
                                        (change)="onSubjectChange()"
                                        [(ngModel)]="subjectsSelected">
                                        <ng-template ng-header-tmp>
																					<button (click)="subjectsSelected = subjects" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameSubject}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--primary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de Materia</span>
                                    <ng-select [items]="subjectGroups"
                                                bindLabel="userapp.CedocEmail"
                                                placeholder="Seleccionar grupo de materia"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onSubjectGroupChange()"
                                                [(ngModel)]="subjectGroupsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="subjectGroupsSelected = subjectGroups" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.userapp.CedocEmail}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template >


            <ng-template [ngSwitchCase]="eTypeRole.Docente">
                <div class="card-header ">
                    <div class="flex-3-cols">
                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>

                                    <ng-select [items]="categories"
                                                bindLabel="NameCategoryCourse"
                                                placeholder="Seleccionar Categoría"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onCategoryChange()"
                                                [(ngModel)]="categoriesSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="categoriesSelected = categories; onCategoryChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
                                                </ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent" >
                            <div class="form-group cat__core__step cat__core__step--secondary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>
                                    <ng-select class="mySelect" [items]="typeCourses"
																			bindLabel="NameTypeCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onTypeCourseChangeDocente()"
																			[(ngModel)]="typeCoursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="typeCoursesSelected = typeCourses; onTypeCourseChangeDocente()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameTypeCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent" >
                            <div class="form-group cat__core__step cat__core__step--primary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nivel de escolaridad</span>
                                    <ng-select  class="mySelect" [items]="courses"
																			bindLabel="NameCourse"
																			placeholder="Seleccionar Nivel de escolaridad"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCourseChange()"
																			[(ngModel)]="coursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="coursesSelected = courses; onCourseChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourse}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" >
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Área académica</span>
                                    <ng-select [items]="academicAreas"
																			bindLabel="NameArea"
																			placeholder="Seleccionar Área académica"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onAcademicAreaChange()"
																			[(ngModel)]="academicAreasSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="academicAreasSelected = academicAreas; onAcademicAreaChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameArea}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--secundary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de Materia</span>
                                    <ng-select [items]="subjects"
																			bindLabel="NameSubject"
																			placeholder="Seleccionar nombre de materia"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onSubjectChange()"
																			[(ngModel)]="subjectsSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="subjectsSelected = subjects; onSubjectChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameSubject}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de Materia</span>
                                    <ng-select [items]="subjectGroups"
                                                bindLabel="userapp.CedocEmail"
                                                placeholder="Seleccionar grupo de materia"
                                                [closeOnSelect]="false"
                                                multiple="true"
                                                (change)="onSubjectGroupChange()"
                                                [(ngModel)]="subjectGroupsSelected">
                                                <ng-template ng-header-tmp>
                                                    <button (click)="subjectGroupsSelected = subjectGroups; onSubjectGroupChange()" 
                                                    class="btn btn-sm btn-block btn-primary">Todos</button>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.userapp.CedocEmail}}
                                                </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template >

            <ng-template [ngSwitchCase]="eTypeRole.Individual">
                <div class="card-header ">
                    <div class="flex-3-cols">
                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>

                                    <ng-select [items]="categories"
																			bindLabel="NameCategoryCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCategoryChange()"
																			[(ngModel)]="categoriesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="categoriesSelected = categories; onCategoryChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
																			</ng-template>
																			bindLabel="NameCategoryCourse"
																			placeholder="Seleccionar Categoría"
																			appendTo="body"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onCategoryChange()"
																			[(ngModel)]="categoriesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="categoriesSelected = categories" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCategoryCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--secondary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Categoría</span>
                                    <ng-select class="mySelect" [items]="typeCourses"
																			bindLabel="NameTypeCourse"
																			placeholder="Seleccionar Categoría"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onTypeCourseChange()"
																			[(ngModel)]="typeCoursesSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="typeCoursesSelected = typeCourses; onTypeCourseChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameTypeCourse}}
																			</ng-template>
                                    </ng-select>

                                </div>
                            </div>
                        </div>


                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--primary">
                                <div class="cat__core__step__desc">
																	<span class="cat__core__step__title">Nivel de escolaridad</span>
																	<ng-select  class="mySelect" [items]="courses"
																		bindLabel="NameCourse"
																		placeholder="Seleccionar Nivel de escolaridad"
																		[closeOnSelect]="false"
																		multiple="true"
																		(change)="onCourseChange()"
																		[(ngModel)]="coursesSelected">
																		<ng-template ng-header-tmp>
																				<button (click)="coursesSelected = courses; onCourseChange()" 
																				class="btn btn-sm btn-block btn-primary">Todos</button>
																		</ng-template>
																		<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																				<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameCourse}}
																		</ng-template>
																	</ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent">
                            <div class="form-group cat__core__step cat__core__step--danger">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Área académica</span>
                                    <ng-select [items]="academicAreas"
																				bindLabel="NameArea"
																				placeholder="Seleccionar Área académica"
																				[closeOnSelect]="false"
																				multiple="true"
																				(change)="onAcademicAreaChange()"
																				[(ngModel)]="academicAreasSelected">
																				<ng-template ng-header-tmp>
																						<button (click)="academicAreasSelected = academicAreas; onAcademicAreaChange()" 
																						class="btn btn-sm btn-block btn-primary">Todos</button>
																				</ng-template>
																				<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																						<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameArea}}
																				</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--secundary">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Nombre de Materia</span>
                                    <ng-select [items]="subjects"
																			bindLabel="NameSubject"
																			placeholder="Seleccionar nombre de materia"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onSubjectChange()"
																			[(ngModel)]="subjectsSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="subjectsSelected = subjects; onSubjectChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.NameSubject}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="divContent" *ngIf="principalAppName == 'cedoc'">
                            <div class="form-group cat__core__step cat__core__step--success">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Grupo de Materia</span>
                                    <ng-select [items]="subjectGroups"
																			bindLabel="userapp.CedocEmail"
																			placeholder="Seleccionar grupo de materia"
																			[closeOnSelect]="false"
																			multiple="true"
																			(change)="onSubjectGroupChange()"
																			[(ngModel)]="subjectGroupsSelected">
																			<ng-template ng-header-tmp>
																					<button (click)="subjectGroupsSelected = subjectGroups; onSubjectGroupChange()" 
																					class="btn btn-sm btn-block btn-primary">Todos</button>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
																					<input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.userapp.CedocEmail}}
																			</ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template >
            
            <ng-template [ngSwitchCase]="eTypeRole.Administrativos">
                <div class="card-header ">
                    <div class="row justify-content-md-center">
                        <div class="col-lg-8">
                            <div class="form-group cat__core__step cat__core__step--warning">
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Rol</span>

                                    <ng-select [items]="roles"
                                        bindLabel="name"
                                        placeholder="Seleccionar Rol"
                                        [closeOnSelect]="false"
                                        multiple="true"
                                        (change)="onRoleChange()"
                                        [(ngModel)]="rolesSelected">
                                    </ng-select>
                                    <ng-template ng-header-tmp>
                                        <button (click)="rolesSelected = roles" 
                                        class="btn btn-sm btn-block btn-primary">Todos</button>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template >

        </ng-container>        
			</div>    
<!-- Modal Estadisticas -->
<div class="transfer-modal__container" [ngStyle]="{'visibility': showChartModal ? 'visible' : 'hidden'}" >
	<div class="transfer-modal">
			<div class="transfer-modal__header-container">
				<div class="transfer-modal__header">
					<h2 class="transfer-modal__title">
						 <span *ngIf="noticias">Noticias</span> <span *ngIf="!noticias">Correos</span>
					</h2>
					<div class="float-leftt">
						<button (click)="modalNoticias()" [ngClass]="{'acitveNotive': noticias}" class="btn btn-sm btn-outline-primary ladda-button divNoticias">
							<span >Noticias</span>
						</button>
						<button (click)="modalCorreos()" [ngClass]="{'acitveNotive': !noticias}" class="btn btn-sm btn-outline-primary ladda-button">
							<span >Correos</span>
						</button>
					</div>
					<button (click)="_showChartModal()" class="button "><em class="icon-i-close close-icon"></em></button>
				</div>
			</div>
			<div>
				<div *ngIf="noticias" >
					<div class="row">
						<div class="col-12">
							<div class="divliceos">
								<label class="textPrincipal">
									<label> Seleccione Liceo</label>
								</label>
								<select class="form-control" #CareAreaID name="CareAreaID"
									[(ngModel)]="schoolIget" required (change)="cambioColegio()">
									<option [ngValue]="0"  selected *ngIf="schoolID==1">
										Todos los Liceos
									</option>
									<option *ngFor="let school of schools" [ngValue]="school.id">
											{{school?.NameTSchool}}
									</option>
								</select>
							</div>
						</div>
						<div class="col-6 ">
							<div class="divContenido">
								<div class="startDate">
									<label for="endDated">Fecha Inicio:</label>
									<input type="date" id="startDate inputFechas" [(ngModel)]="startDate" (change)="showChartModalConect()">
								</div>
								<div class="zindez">
									<label for="endDated">Fecha Fin:</label>
									<input type="date" id="endDate inputFechas" [(ngModel)]="endDate" (change)="showChartModalConect()">
								</div>
							</div>
							<br>
							<br>
							<table  >
								<tbody>
									<tr *ngFor="let item of pieChartDatasets[0].data; let i = index">
										<td class="text">
											<span class="chart-box__color" [ngStyle]="{
													'background-color':
														chartColors[0].backgroundColor[i]
												}" style="margin-left:10px ; margin-right: 10px;"></span>
										</td>
										<td class="textPrincipal">  {{ pieChartLabel[i] }}:  {{ item }} </td>
									</tr>
								</tbody>
							</table>
						</div>
						<div  class="col-6 contentInfo">
							<div *ngIf="pieChartDatasets[0].data.length>1">
								<canvas baseChart
									[type]="'doughnut'"
									[datasets]="pieChartDatasets"
									[labels]="pieChartLabels"
									[options]="pieChartOptions"
									[plugins]="pieChartPlugins"
									[legend]="pieChartLegend">
								</canvas>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!noticias" >
					<div class="row">
						<div class="col-6 ">
							<div class="divContenido">
								<div class="startDate">
									<label for="endDated">Fecha Inicio:</label>
									<input type="date" id="startDate inputFechas" [(ngModel)]="startDate" (change)="showChartModalEventos()">
								</div>
								<div class="zindez">
									<label for="endDated">Fecha Fin:</label>
									<input type="date" id="endDate inputFechas" [(ngModel)]="endDate" (change)="showChartModalEventos()">
								</div>
							</div>
							<br>
							<table>
								<tbody>
									<tr *ngFor="let item of pieChartDatasetsEventos[0].data; let i = index">
										<td class="text">
											<span class="chart-box__color" [ngStyle]="{
													'background-color':
														chartColors[0].backgroundColor[i]
												}" style="margin-left:10px ; margin-right: 10px;"></span>
										</td>
										<td class="textPrincipal">  {{ pieChartLabelEventos[i] }}:  {{ item }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-6 contentInfo">
							<div *ngIf="pieChartDatasetsEventos[0].data.length>1">
								<canvas baseChart
									[type]="'doughnut'"
									[datasets]="pieChartDatasetsEventos"
									[labels]="pieChartLabels"
									[options]="pieChartOptions"
									[plugins]="pieChartPlugins"
									[legend]="pieChartLegend">
								</canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
</div>
