import { School } from './school';
import { UserApp } from './userApp';

export class RoleMapping {
    public id:number;
    public roleId:number;
    public SchoolID:number;
    public principalType?:string;
    public principalId?:number;

    public school: School;
    public role: Role;
    public userapp?: UserApp;
}

export class Role {
    public id:number;
    public name:string;
}
