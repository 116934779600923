<div *ngIf="school" id="myNav" [ngStyle]="{'height': heightExp}" class="overlay">

  <a class="closebtn" (click)="closeNav()">&times;</a>
  
  <div class="overlay-content">

    <div class="title" *ngIf="principalAppName == 'cedoc'">
      Selecciona Escuela
    </div>
    <div class="title" *ngIf="principalAppName != 'cedoc'">
      Seleccione el Liceo
    </div>
    <div  *ngIf="school.parentSchool" (click)="getSchools(school.parentSchool.id)" class="current-school" >
        <i class="fa fa-angle-left mr-3"></i> 
        <img src="{{school.Icono}}"class="rounded-logo">
        <strong> {{school.NameTSchool}} </strong>
    </div>

    <div class="content">
      <div  class="list-school">
        <div (click)="closeNav()" class="item-school">
          <img src="{{school.Icono}}"class="rounded-logo">
          <strong>  {{school.SchoolAcronim}} - {{school.NameTSchool}} </strong>
        </div>
      </div>

      <div *ngFor='let chSchool of school.childrenSchools' class="list-school">
        <div (click)="getSchools(chSchool.id)" class="item-school">
          <img src="{{chSchool.Icono}}"class="rounded-logo">
          <strong> {{chSchool.SchoolAcronim}} - {{chSchool.NameTSchool}}  </strong>
        </div>
      </div>
    </div>
  </div>
</div>
<span class="menuSchool" (click)="openNav()">  
  <img src="{{school.Icono}}"class="rounded-logo">
  <strong> {{school.SchoolAcronim}} - {{school.NameTSchool}} </strong>
</span>
