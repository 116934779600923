import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormField } from 'src/app/models/formField';
import { FormInstrument } from 'src/app/models/formInstrument';
import { FormModule } from 'src/app/models/formModule';
import { IndexForm } from 'src/app/models/indexForm';
import { IndexFormUser } from 'src/app/models/indexFormUser';
import { UserAnswer } from 'src/app/models/userAnswer';
import { AuthService } from 'src/app/services/auth.service';
import { FormfolderService } from 'src/app/services/formfolder.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'autoeval-template-user',
  templateUrl: './template-user.component.html',
  styleUrls: ['./template-user.component.css']
})
export class TemplateUserComponent implements OnInit {

  public myIndexFormUser: IndexFormUser;
  public userAnswers: UserAnswer[];
  public myID: number = 0;

  public isDataReady:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formfolderService: FormfolderService,
    private authService: AuthService
  ) {
    this.myIndexFormUser = new IndexFormUser();
    this.myIndexFormUser.IndexForm =  new IndexForm()
    this.myIndexFormUser.IndexForm.FormInstrument  = new FormInstrument();
    this.myIndexFormUser.IndexForm.FormInstrument.FormModules = [ new FormModule()];
    this.myIndexFormUser.IndexForm.FormInstrument.FormModules[0].FormFields = [new FormField];

    this.myID = this.authService.getUser();
   }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.formfolderService.getIndexFormUserId(params['id']).subscribe(
          data => {
            this.myIndexFormUser = data;

            if(this.myIndexFormUser.IsAnswer==false && this.myIndexFormUser.UserID==this.myID)
              this.initAnswerForm();
          });
        
      }
    });
  }

  initAnswerForm(){
    this.userAnswers = this.myIndexFormUser.IndexForm.FormInstrument.FormModules.reduce(
      (r,e )=> {

        r.push(...e.FormFields.map(f => {
          let ua: UserAnswer = new UserAnswer();
          ua.IndexFormUserID = this.myIndexFormUser.id;
          ua.FormFieldID = f.id;
          ua.value="0";
          return ua;
        }));

        return r;

    },[]);

    this.isDataReady = true;
  }

  setValue(value:string,id:number){
    let foundIndex = this.userAnswers.findIndex(x => x.FormFieldID == id);
    this.userAnswers[foundIndex].value = value;
  }

  saveInstrument(){
    let average = this.userAnswers.reduce((r,e)=>{ 
      let num = parseInt(e.value);
      if (isNaN(num)) { return r + 0; }
      return r + num; 
    },0);
    this.myIndexFormUser.IsAnswer = true;
    this.myIndexFormUser.Percentage = average/this.userAnswers.length;
    this.formfolderService.newUserAnswers(this.userAnswers).subscribe();
    this.formfolderService.UpdateIndexFormUsers(this.myIndexFormUser).subscribe(
      r => {
        Swal.fire('Correcto', 'Gracias por responder','success');
        this.isDataReady=false;
      }
    );
  }
}
